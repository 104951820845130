export const digCompEduElementaryCompetences = {
  "Professional Engagement": ["Organizational communication", "Professional collaboration", "Reflective practice", "Digital Continuous Professional Developement"],
  "Digital Resources": ["Selecting", "Creating/Modifying", "Managing/protecting/sharing"],
  "Teaching and Learning": ["Teaching", "Guidance", "Collaborative learning", "Self-regulated learning"],
  "Assessment": ["Assessment strategies", "Analysing evidence", "Feedback & Planning"],
  "Empowering Learners": ["Accessibility & Inclusion", "Differentiation & personalisation", "Actively engaging learners"],
  "Facilitating Learners Digital Competence": ["Information & Media Literacy", "Communication", "Content creation", "Responsible use", "Problem solving"]
};

export const digCompEduActivities = {
  "Organizational communication": [
    "DIGCOMPEDU 1.1.1 : To use digital technologies to make additional learning resources and information available to learners (and parents)",
    "DIGCOMPEDU 1.1.2 : To use digital technologies to communicate organisational procedures to learners and parents, e.g. rules, appointments, events",
    "DIGCOMPEDU 1.1.3 : To use digital technologies to inform learners and parents on an individual basis, e.g. on progress and issues of concern",
    "DIGCOMPEDU 1.1.4 : To use digital technologies to communicate with colleagues in the same organisation and beyond",
    "DIGCOMPEDU 1.1.5 : To use digital technologies to communicate with third parties relevant to the educational project, e.g. experts to be invited, places to be visited",
    "DIGCOMPEDU 1.1.6 : To communicate via the organisation's website or through corporate digital technologies, platforms or communication services contracted",
    "DIGCOMPEDU 1.1.7 : To contribute with content to the organisation's website or virtual learning environment",
    "DIGCOMPEDU 1.1.8 : To contribute to collaboratively developing and improving organisational communication strategies"],

  "Professional collaboration": [
    "DIGCOMPEDU 1.2.1 : To use digital technologies to collaborate with other educators, on a dedicated project or task",
    "DIGCOMPEDU 1.2.2 : To use digital technologies to share and exchange knowledge, resources and experiences with colleagues and peers",
    "DIGCOMPEDU 1.2.3 : To use digital technologies to collaboratively develop educational resources",
    "DIGCOMPEDU 1.2.4 : To use professional collaborative networks to explore and reflect on new pedagogic practices and methods",
    "DIGCOMPEDU 1.2.5 : To use professional collaborative networks as a source for one's own professional development"],

  "Reflective practice": [
    "DIGCOMPEDU 1.3.1 : To critically reflect on one's own digital and pedagogic practice",
    "DIGCOMPEDU 1.3.2 : To identify competence gaps and areas for improvement",
    "DIGCOMPEDU 1.3.3 : To seek the help of others in improving one's digital and pedagogical practice",
    "DIGCOMPEDU 1.3.4 : To seek targeted training and use opportunities for continuous professional development",
    "DIGCOMPEDU 1.3.5 : To seek to continuously expand and enhance one's repertoire of digital pedagogical practices",
    "DIGCOMPEDU 1.3.6 : To help others in developing their digital pedagogical competence",
    "DIGCOMPEDU 1.3.7 : At the organisational level, to reflect on and provide critical feedback on digital policies and practices",
    "DIGCOMPEDU 1.3.8 : To actively contribute to further developing organisational practices, policies and visions on the use of digital technologies"],

  "Digital Continuous Professional Developement": [
    "DIGCOMPEDU 1.4.1 : To use the internet to identify suitable training and professional development opportunities",
    "DIGCOMPEDU 1.4.2 : To use the internet to update one's subjectspecific competences",
    "DIGCOMPEDU 1.4.3 : To use the internet to learn about new pedagogical methods and strategies",
    "DIGCOMPEDU 1.4.4 : To use the internet to search for and identify digital resources which support professional development",
    "DIGCOMPEDU 1.4.5 : To use the exchange in digital professional communities as a source of professional development",
    "DIGCOMPEDU 1.4.6 : To use online training opportunities, e.g. video tutorials, MOOCs, webinars etc",
    "DIGCOMPEDU 1.4.7 : To use digital technologies and environments to provide training opportunities for colleagues and peers"],

  "Selecting": [
    "DIGCOMPEDU 2.1.1 : To formulate appropriate search strategies to identify digital resources for teaching and learning",
    "DIGCOMPEDU 2.1.2 : To select suitable digital resources for teaching and learning, considering the specific learning context and learning objective",
    "DIGCOMPEDU 2.1.3 : To critically evaluate the credibility and reliability of digital sources and resources",
    "DIGCOMPEDU 2.1.4 : To consider possible restrictions to the use or re-use of digital resources (e.g. copyright, file type, technical requirements, legal provisions,accessibility).",
    "DIGCOMPEDU 2.1.5 : To assess the usefulness of digital resources in addressing the learning objective, the competence levels of the concrete learner group as well as the pedagogic approach chosen."],

  "Creating/Modifying": [
    "DIGCOMPEDU 2.2.1 : To modify and edit existing digital resources, where this is permitted",
    "DIGCOMPEDU 2.2.2 : To combine and mix existing digital resources or parts thereof, where this is permitted",
    "DIGCOMPEDU 2.2.3 : To create new digital educational resources",
    "DIGCOMPEDU 2.2.4 : To jointly create with others digital educational resources",
    "DIGCOMPEDU 2.2.5 : To consider the specific learning objective, context, pedagogical approach, and learner group, when adapting or creating digital learning resources",
    "DIGCOMPEDU 2.2.6 : To understand different licences attributed to digital resources and the implications for their re-use"],

  "Managing/protecting/sharing": [
    "DIGCOMPEDU 2.3.1 : To share resources using links or as attachments, e.g. to e-mails",
    "DIGCOMPEDU 2.3.2 : To share resources on online platforms or personal or organisational websites/blogs",
    "DIGCOMPEDU 2.3.3 : To share one's own repositories of resources with others, managing their access and rights as appropriate",
    "DIGCOMPEDU 2.3.4 : To respect possible copyright restrictions to using, re-using and modifying digital resources",
    "DIGCOMPEDU 2.3.5 : To appropriately reference sources when sharing or publishing resources subject to copyright",
    "DIGCOMPEDU 2.3.6 : To attribute (open) licenses to self-created resources",
    "DIGCOMPEDU 2.3.7 : To take measures to protect sensitive data and resources (e.g. students' grades, exams)",
    "DIGCOMPEDU 2.3.8 : To share administrative and student-related data with colleagues, students and parents, as appropriate"],

  "Teaching": [
    "DIGCOMPEDU 3.1.1 : To use classroom technologies to support instruction, e.g. electronic whiteboards, mobile devices",
    "DIGCOMPEDU 3.1.2 : To structure the lesson so that different (teacher-led and learner-led) digital activities jointly re-inforce the learning objective",
    "DIGCOMPEDU 3.1.3 : To set up learning sessions, activities and interactions in a digital environment",
    "DIGCOMPEDU 3.1.4 : To structure and manage content, collaboration and interaction in a digital environment",
    "DIGCOMPEDU 3.1.5 : To consider how educator-led digital interventions - whether face-to-face or in a digital environment - can best support the learning objective",
    "DIGCOMPEDU 3.1.6 : To reflect on the effectiveness and appropriateness of the digital pedagogical strategies chosen and flexibly adjust methods and strategies",
    "DIGCOMPEDU 3.1.7 : To experiment with and develop new formats and pedagogical methods for instruction (e.g. flipped classroom)"],

  "Guidance": [
    "DIGCOMPEDU 3.2.1 : To use digital communication tools to respond promptly to learners' questions and doubts, e.g. on homework assignments",
    "DIGCOMPEDU 3.2.2 : To set up learning activities in digital environments, having foreseen learners' needs for guidance and catering for them",
    "DIGCOMPEDU 3.2.3 : To interact with learners in collaborative digital environments",
    "DIGCOMPEDU 3.2.4 : To digitally monitor student behaviour in class and offer guidance when needed",
    "DIGCOMPEDU 3.2.5 : To use digital technologies to remotely monitor student progress and intervene when needed, while allowing for self-regulation",
    "DIGCOMPEDU 3.2.6 : To experiment with and develop new forms and formats for offering guidance and support, using digital technologies"],

  "Collaborative learning": [
    "DIGCOMPEDU 3.3.1 : To implement collaborative learning activities in which digital devices, resources or digital information strategies are used",
    "DIGCOMPEDU 3.3.2 : To implement collaborative learning activities in a digital environment, e.g. using blogs, wikis, learning management systems",
    "DIGCOMPEDU 3.3.3 : To employ digital technologies for collaborative knowledge exchange among learners",
    "DIGCOMPEDU 3.3.4 : To monitor and guide learners in their collaborative knowledge generation in digital environments",
    "DIGCOMPEDU 3.3.5 : To require learners to digitally present their collaborative efforts and assist them in doing so",
    "DIGCOMPEDU 3.3.6 : To use digital technologies for peer-assessment and as a support for collaborative self-regulation and peer-learning",
    "DIGCOMPEDU 3.3.7 : To use digital technologies to experiment with new formats and methods for collaborative learning"],

  "Self-regulated learning": [
    "DIGCOMPEDU 3.4.1 : To use digital technologies (e.g. blogs, diaries, planning tools) to allow learners to plan their own learning",
    "DIGCOMPEDU 3.4.2 : To use digital technologies to allow learners to collect evidence and record progress, e.g. audio or video recordings, photos",
    "DIGCOMPEDU 3.4.3 : To use digital technologies (e.g. ePortfolios, learners' blogs) to allow learners to record and showcase their work",
    "DIGCOMPEDU 3.4.4 : To use digital technologies to enable learners to reflect on and self-assess their learning process"],

  "Assessment strategies": [
    "DIGCOMPEDU 4.1.1 : To use digital assessment tools to monitor the learning process and obtain information on learners' progress",
    "DIGCOMPEDU 4.1.2 : To use digital technologies to enhance formative assessment strategies, e.g. using classroom response systems, quizzes, games",
    "DIGCOMPEDU 4.1.3 : To use digital technologies to enhance summative assessment in tests, e.g. through computer-based tests, implementing audio or video (e.g. in language learning), using simulations or subject-specific digital technologies as test environments",
    "DIGCOMPEDU 4.1.4 : To use digital technologies to scaffold learners' assignments and their assessment, e.g. through ePortfolios",
    "DIGCOMPEDU 4.1.5 : To use of a variety of digital and non-digital assessment formats and be aware of their benefits and drawbacks",
    "DIGCOMPEDU 4.1.6 : To critically reflect on the appropriateness digital assessment approaches and adapt strategies accordingly"],

  "Analysing evidence": [
    "DIGCOMPEDU 4.2.1 : To design and implement learning activities which generate data on learner activity and performance",
    "DIGCOMPEDU 4.2.2 : To use digital technologies to record, compare and synthesize data on learner progress",
    "DIGCOMPEDU 4.2.3 : To be aware that learner activity in digital environments generates data that can be used to inform teaching and learning",
    "DIGCOMPEDU 4.2.4 : To analyse and interpret available evidence on learner activity and progress, including the data generated by the digital technologies used",
    "DIGCOMPEDU 4.2.5 : To consider, combine and evaluate different sources of evidence on learner progress and performance",
    "DIGCOMPEDU 4.2.6 : To critically value the evidence available to inform teaching and learning"],

  "Feedback & Planning": [
    "DIGCOMPEDU 4.3.1 : To use digital technology to grade and give feedback on electronically submitted assignments",
    "DIGCOMPEDU 4.3.2 : To use assessment management systems to enhance the effectiveness of feedback provision",
    "DIGCOMPEDU 4.3.3 : To use digital technologies to monitor learner progress and provide support when needed",
    "DIGCOMPEDU 4.3.4 : To adapt teaching and assessment practices, based on the data generated by the digital technologies used",
    "DIGCOMPEDU 4.3.5 : To provide personal feedback and offer differentiated support to learners, based on the data generated by the digital technologies used",
    "DIGCOMPEDU 4.3.6 : To enable learners to evaluate and interpret the results of formative, summative, self- and peer-assessments",
    "DIGCOMPEDU 4.3.7 : To assist learners in identifying areas for improvement and jointly develop learning plans to address these areas",
    "DIGCOMPEDU 4.3.8 : To use digital technologies to enable learners and/or parents to remain updated on progress and make informed choices on future learning priorities, optional subject or future studies"],

  "Accessibility & Inclusion": [
    "DIGCOMPEDU 5.1.1 : To provide equitable access to appropriate digital technologies and resources, e.g. ensuring that all students have access to the digital technologies used",
    "DIGCOMPEDU 5.1.2 : To select and employ digital pedagogical strategies which respond to learners' digital context, e.g. contextual constraints to their technology use (e.g. availability), competences, expectations, attitudes, misconceptions and misuses",
    "DIGCOMPEDU 5.1.3 : To employ digital technologies and strategies, e.g. assistive technologies, designed for learners' in need of special support (e.g. learners with physical or mental constraints; learners with learning disorders)",
    "DIGCOMPEDU 5.1.4 : To consider and respond to potential accessibility issues when selecting, modifying or creating digital resources and to provide alternative or compensatory tools or approaches for learners with special needs",
    "DIGCOMPEDU 5.1.5 : To employ design principles for increasing accessibility for the resources and digital environments used in teaching",
    "DIGCOMPEDU 5.1.6 : To continuously monitor and reflect on the suitability of the measures implemented to improve accessibility and adapt strategies accordingly"],

  "Differentiation & personalisation": [
    "DIGCOMPEDU 5.2.1 : To use digital technologies to address the special needs of individual learners (e.g. dyslexia, ADHD, overachievers)",
    "DIGCOMPEDU 5.2.2 : To allow for different learning pathways, levels and speeds when designing, selecting and implementing digital learning activities",
    "DIGCOMPEDU 5.2.3 : To devise individual learning plans and use digital technologies to support these"],

  "Actively engaging learners": [
    "DIGCOMPEDU 5.3.1 : To use digital technologies to visualise and explain new concepts in a motivating and engaging way, e.g. by employing animations or videos",
    "DIGCOMPEDU 5.3.2 : To employ digital learning environments or activities which are motivating and engaging,e.g. games, quizzes",
    "DIGCOMPEDU 5.3.3 : To put learners' active uses of digital technologies at the centre of the instructional process",
    "DIGCOMPEDU 5.3.4 : To use digital technologies to allow learners to actively engage with the subject matter at hand, e.g. using different senses, manipulating virtual objects, varying the problem set up to enquire into its structure, etc.",
    "DIGCOMPEDU 5.3.5 : To select appropriate digital technologies for fostering active learning in a given learning context or for a specific learning objective",
    "DIGCOMPEDU 5.3.6 : To reflect on how suitable the different digital technologies used are in increasing learners' active learning, and to adapt strategies and choices accordingly"],

  "Information & Media Literacy": [
    "DIGCOMPEDU 6.1.1 : To articulate information needs, to search for data, information and content in digital environments, to access them and to navigate between them",
    "DIGCOMPEDU 6.1.2 : To create and update personal search strategies",
    "DIGCOMPEDU 6.1.3 : To adapt search strategies based on the quality of information found",
    "DIGCOMPEDU 6.1.4 : To analyse, compare and critically evaluate the credibility and reliability of sources of data, information and digital content",
    "DIGCOMPEDU 6.1.5 : To organise, store and retrieve data, information and content in digital environments",
    "DIGCOMPEDU 6.1.6 : To organise and process information in a structured environment"],

  "Communication": [
    "DIGCOMPEDU 6.2.1 : To interact through a variety of digital technologies",
    "DIGCOMPEDU 6.2.2 : To understand appropriate digital communication means for a given context",
    "DIGCOMPEDU 6.2.3 : To share data, information and digital content with others through appropriate digital technologies",
    "DIGCOMPEDU 6.2.4 : To know about referencing and attribution practices",
    "DIGCOMPEDU 6.2.5 : To participate in society through the use of public and private digital services",
    "DIGCOMPEDU 6.2.6 : To seek opportunities for self-empowerment and for participatory citizenship through appropriate digital technologies",
    "DIGCOMPEDU 6.2.7 : To use digital technologies for collaborative processes, and for co-construction and cocreation of resources and knowledge",
    "DIGCOMPEDU 6.2.8 : To be aware of behavioural norms and know how while using digital technologies and interacting in digital environments",
    "DIGCOMPEDU 6.2.9 : To adapt communication strategies to the specific audience and to be aware of cultural and generational diversity in digital environments",
    "DIGCOMPEDU 6.2.10 : To create and manage one or multiple digital identities",
    "DIGCOMPEDU 6.2.11 : To protect one's own reputation",
    "DIGCOMPEDU 6.2.12 : To deal with the data that one produces through several digital technologies, environments and services"],

  "Content creation": [
    "DIGCOMPEDU 6.3.1 : To create and edit digital content in different formats",
    "DIGCOMPEDU 6.3.2 : To express themselves through digital means",
    "DIGCOMPEDU 6.3.3 : To modify, refine, improve and integrate information and content into an existing body of knowledge",
    "DIGCOMPEDU 6.3.4 : To create new, original and relevant content and knowledge",
    "DIGCOMPEDU 6.3.5 : To understand how copyright and licenses apply to data, information and digital content",
    "DIGCOMPEDU 6.3.6 : To plan and develop a sequence of understandable instructions for a computing system to solve a given problem or perform a specific task"],

  "Responsible use": [
    "DIGCOMPEDU 6.4.1 : To protect devices and digital content, and to understand risks and threats in digital environments",
    "DIGCOMPEDU 6.4.2 : To understand safety and security measures",
    "DIGCOMPEDU 6.4.3 : To protect personal data and privacy in digital environments",
    "DIGCOMPEDU 6.4.4 : To understand how to use and share personal information while being able to protect oneself and others from damages",
    "DIGCOMPEDU 6.4.5 : To understand that digital services use a “Privacy policy” on how personal data is used",
    "DIGCOMPEDU 6.4.6 : To avoid health risks and threats to physical and psychological well-being while using digital technologies",
    "DIGCOMPEDU 6.4.7 : To protect oneself and others from possible dangers in digital environments (e.g. cyberbullying)",
    "DIGCOMPEDU 6.4.8 : To be aware of digital technologies for social wellbeing and social inclusion",
    "DIGCOMPEDU 6.4.9 : To be aware of the environmental impact of digital technologies and their use",
    "DIGCOMPEDU 6.4.10 : To react immediately and effectively when learners' wellbeing is threatened in digital environments (e.g. cyberbullying)"],

  "Problem solving": [
    "DIGCOMPEDU 6.5.1 : To identify technical problems when operating devices and using digital environments, and to solve them",
    "DIGCOMPEDU 6.5.2 : To adjust and customise digital environments to personal needs",
    "DIGCOMPEDU 6.5.3 : To identify, evaluate, select and use digital technologies and possible technological responses to solve a given task or problem",
    "DIGCOMPEDU 6.5.4 : To use digital technologies in innovative ways to create knowledge",
    "DIGCOMPEDU 6.5.5 : To understand where their digital competence needs to be improved or updated",
    "DIGCOMPEDU 6.5.6 : To support others in their digital competence development",
    "DIGCOMPEDU 6.5.7 : To seek opportunities for self-development and to keep up-to-date with the digital evolution"]
};

export const RCNumObligatoireElementaryCompetences = {
  "Engagement professionnel": ["Citoyenneté numérique et dimension critique", "Communication professionnelle", "Collaboration professionnelle", "Pratique réflexive professionnelle", "Formation continue au numérique"],
  "Ressources pédagogiques": ["Modalités", "Apports", "Conditions", "Normes légales"],
  "Enseignement et apprentissage": ["Enseignement avec des technologies numériques", "Soutien à l'apprentissage", "Enseignement et didactique", "Enseigner la science informatique", "Enseigner la littératie médiatique", "Développer les enjeux sociaux du numérique"],
  "Evaluation": ["Stratégies d'évaluation", "Analyse des résultats", "Rétroaction", "Réguler son enseignement"],
  "Autonomie des apprenant.es": ["Accessibilité et inclusion", "Différenciation et adaptation", "Mobilisation active des apprenant.es"],
  "Développement des compétences numériques des apprenant.es": ["Médias", "Science informatique", "Usages"]
};

export const RCNumPostObligatoireElementaryCompetences = {
  "Engagement professionnel": ["Citoyenneté numérique et dimensions critique et juridique", "Communication professionnelle", "Collaboration professionnelle", "Développement professionnel continu"],
  "Ressources pédagogiques": ["Modalités", "Apports", "Conditions", "Normes légales"],
  "Enseignement et apprentissage": ["Intégration des technologies numériques dans l'enseignement", "Utilisation des technologies numériques en soutien à l'apprentissage", "Enseignement et didactique"],
  "Evaluation": ["Stratégies d'évaluation", "Régulation de l'enseignement", "Régulation des apprentissages"],
  "Autonomie des apprenants": ["Mobilisation active des apprenant.es", "Accessibilité et inclusion", "Différenciation et adaptation"],
  "Développement des compétences numériques des apprenant.es": ["Promotion de la littératie numérique", "Soutien à la communication et la collaboration", "Accompagnement à la création de contenus numériques", "Sensibilisation à la sécurité", "Encouragement à la résolution de problèmes"]
};

export const RCNumPostObligatoireActivities = {
  "Citoyenneté numérique et dimensions critique et juridique": [
    "RCNUM POST-OBLIGATOIRE 1.0.1 : Gérer son identité numérique en tant qu'agent.e de la fonction publique",
    "RCNUM POST-OBLIGATOIRE 1.0.2 : Manipuler divers environnements numériques, notamment des solutions libres",
    "RCNUM POST-OBLIGATOIRE 1.0.3 : Identifier et tenir compte des enjeux de société et en particulier de la numérisation des phénomènes scolaires",
    "RCNUM POST-OBLIGATOIRE 1.0.4 : Choisir les environnements numériques en étant conscients des intérêts en jeu",
    "RCNUM POST-OBLIGATOIRE 1.0.5 : Respecter les dispositions contraignantes en matière de protection des données dans tous les domaines, ainsi que les aspects de confidentialité liés à tous types de documents numériques dans le contexte professionnel"],

  "Communication professionnelle": [
    "RCNUM POST-OBLIGATOIRE 1.1.1 : Sélectionner et utiliser des outils/environnements de communication numérique ainsi que des pratiques de communication adaptés aux différent.es partenaires",
    "RCNUM POST-OBLIGATOIRE 1.1.2 : Contribuer au développement et à l'amélioration des stratégies de communication organisationnelles"],

  "Collaboration professionnelle": [
    "RCNUM POST-OBLIGATOIRE 1.2.1 : Sélectionner les technologies numériques pour collaborer avec d'autres enseignant.es, partager et échanger des connaissances, des expériences et des pratiques pédagogiques pertinentes, innovantes et collaboratives",
    "RCNUM POST-OBLIGATOIRE 1.2.2 : Participer à la production et la diffusion des ressources éducatives libres (RELs)",
    "RCNUM POST-OBLIGATOIRE 1.2.3 : Mutualiser des pratiques pédago-numériques",
    "RCNUM POST-OBLIGATOIRE 1.2.4 : Effectuer une curation de contenus"],

  "Développement professionnel continu": [
    "RCNUM POST-OBLIGATOIRE 1.3.1 : Sélectionner les sources et les ressources numériques pour le développement professionnel continu",
    "RCNUM POST-OBLIGATOIRE 1.3.2 : Effectuer une veille et co-construire de nouvelles connaissances, par exemple en s'engageant dans des communautés de pratique ou des réseaux professionnels",
    "RCNUM POST-OBLIGATOIRE 1.3.3 : Développer une culture numérique en continu",
    "RCNUM POST-OBLIGATOIRE 1.3.4 : Questionner et développer activement ses propres pratiques et celles de sa communauté pédagogique"],

  "Modalités": [
    "RCNUM POST-OBLIGATOIRE 2.1.1 : Identifier, évaluer et sélectionner des ressources existantes",
    "RCNUM POST-OBLIGATOIRE 2.1.2 : Modifier et compléter des ressources existantes",
    "RCNUM POST-OBLIGATOIRE 2.1.3 : Créer ou co-créer de nouvelles ressources",
    "RCNUM POST-OBLIGATOIRE 2.1.4 : Organiser et partager ses propres ressources avec la communauté éducative"],

  "Apports": [
    "RCNUM POST-OBLIGATOIRE 2.2.1 : Identifier les apports possibles d'une utilisation de ressources pédagogiques pour la construction et la transformation des connaissances disciplinaires et interdisciplinaires",
    "RCNUM POST-OBLIGATOIRE 2.2.2 : Identifier les apports possibles d'une utilisation de ressources pédagogiques pour la construction et la transformation des compétences transversales"],

  "Conditions": [
    "RCNUM POST-OBLIGATOIRE 2.3.1 : Dans le travail avec des ressources pédagogiques, prendre en considération les caractéristiques des ressources, telles que leur accessibilité, leur ergonomie ou leur affordance",
    "RCNUM POST-OBLIGATOIRE 2.3.2 : Dans le travail avec des ressources pédagogiques, prendre en considération les conditions pédagogiques dans lesquelles on souhaite les utiliser, notamment le contexte, l'approche pédagogique, les objectifs d'apprentissage et le groupe d'apprenant.es"],

  "Normes légales": [
    "RCNUM POST-OBLIGATOIRE 2.4.1 : Organiser le contenu numérique et le mettre à la disposition des apprenant.es et, le cas échéant, à d'autres partenaires concernés",
    "RCNUM POST-OBLIGATOIRE 2.4.2 : Protéger efficacement les contenus numériques sensibles",
    "RCNUM POST-OBLIGATOIRE 2.4.3 : Respecter les règles de confidentialité et de droits d'auteur.es",
    "RCNUM POST-OBLIGATOIRE 2.4.4 : Sélectionner et utiliser de manière responsable des licences ouvertes et des ressources éducatives libres (RELs)"],

  "Intégration des technologies numériques dans l'enseignement": [
    "RCNUM POST-OBLIGATOIRE 3.1.1 : Planifier et mettre en œuvre des dispositifs et des ressources numériques dans l'enseignement, afin d'améliorer l'efficacité des interventions pédagogiques",
    "RCNUM POST-OBLIGATOIRE 3.1.2 : Gérer et orchestrer de façon appropriée les interventions d'enseignement avec le numérique",
    "RCNUM POST-OBLIGATOIRE 3.1.3 : Expérimenter et développer de nouveaux formats et méthodes pédagogiques pour l'enseignement"],

  "Utilisation des technologies numériques en soutien à l'apprentissage": [
    "RCNUM POST-OBLIGATOIRE 3.2.1 : Améliorer la communication avec les apprenant.es, individuellement et collectivement, à l'intérieur et à l'extérieur de la classe",
    "RCNUM POST-OBLIGATOIRE 3.2.2 : Favoriser et améliorer la collaboration entre les apprenant.es",
    "RCNUM POST-OBLIGATOIRE 3.2.3 : Offrir des formats d'accompagnement adaptés et ciblés",
    "RCNUM POST-OBLIGATOIRE 3.2.4 : Soutenir les processus d'apprentissage autorégulés, permettre aux apprenant.es de planifier, de suivre et de réfléchir sur leur propre apprentissage, de fournir des preuves de progrès",
    "RCNUM POST-OBLIGATOIRE 3.2.5 : Organiser des espaces d'apprentissage favorisant l'interdisciplinarité ainsi que, le cas échéant, le transfert entre les divers domaines et lieux de formation"
  ],

  "Enseignement et didactique": [
    "RCNUM POST-OBLIGATOIRE 3.3.1 : Planifier et mettre en œuvre une séquence d'enseignement-apprentissage mobilisant des technologies adaptées à la construction des savoirs et au développement des compétences",
    "RCNUM POST-OBLIGATOIRE 3.3.2 : Identifier les possibilités et les limites des outils dans la réalisation de tâches",
    "RCNUM POST-OBLIGATOIRE 3.3.3 : Connaître les effets des outils numériques sur la construction des savoirs et savoir-faire visés",
    "RCNUM POST-OBLIGATOIRE 3.3.4 : Encourager le développement de la littératie médiatique chez les apprenant.es",
    "RCNUM POST-OBLIGATOIRE 3.3.5 : Aborder les enjeux sociaux du numérique avec les apprenant.es",
    "RCNUM POST-OBLIGATOIRE 3.3.6 : Intégrer des éléments de science informatique au sein d'une séquence d'enseignementapprentissage (résolution de problèmes, analyse des données, modélisation et simulation,…)"],

  "Stratégies d'évaluation": [
    "RCNUM POST-OBLIGATOIRE 4.1.1 : Soutenir les démarches d'évaluation diagnostique, formative et sommative à l'aide d'outils numériques",
    "RCNUM POST-OBLIGATOIRE 4.1.2 : Diversifier les formats et les approches d'évaluation, en adéquation avec les besoins des apprenant.es",
    "RCNUM POST-OBLIGATOIRE 4.1.3 : Exploiter les technologies numériques pour documenter des capacités/compétences transversales",
    "RCNUM POST-OBLIGATOIRE 4.1.4 : Garantir un cadre éthique dans la collecte et la gestion des données issues de processus d'évaluation",
    "RCNUM POST-OBLIGATOIRE 4.1.5 : Faire preuve d'ouverture pour d'éventuelles nouvelles formes d'évaluation",
    "RCNUM POST-OBLIGATOIRE 4.1.6 : Mobiliser des stratégies permettant d'évaluer l'acquisition de compétences et de connaissances par les apprenant.es dans les trois domaines de l'éducation numérique (médias, science informatique,usages)"],

  "Régulation de l'enseignement": [
    "RCNUM POST-OBLIGATOIRE 4.2.1 : Exploiter avec discernement les potentialités des technologies numériques pour documenter les actions des individus et des groupes",
    "RCNUM POST-OBLIGATOIRE 4.2.2 : Collecter, analyser et interpréter de manière critique les données numériques documentant l'activité, les performances et les progrès des apprenant.es, afin d'éclairer et de faire évoluer son enseignement",
    "RCNUM POST-OBLIGATOIRE 4.2.3 : Porter un regard critique sur le traitement automatisé des données de l'évaluation",
    "RCNUM POST-OBLIGATOIRE 4.2.4 : Adapter les stratégies d'enseignement et apporter un soutien ciblé, sur la base des résultats des évaluations soutenues par les outils numériques"],

  "Régulation des apprentissages": [
    "RCNUM POST-OBLIGATOIRE 4.3.1 : Exploiter avec discernement les potentialités des technologies numériques pour fournir une rétroaction fréquente et ciblée aux apprenant.es",
    "RCNUM POST-OBLIGATOIRE 4.3.2 : Expliquer aux apprenant.es, représentant.es légaux-légales et autres tiers légitimés les résultats obtenus au moyen de technologies numériques pour faciliter les prises de décisions",
    "RCNUM POST-OBLIGATOIRE 4.3.3 : Identifier les données susceptibles d'être transmises à des partenaires pédago-thérapeutiques à des fins d'accompagnement personnalisé des apprenant.es"],

  "Mobilisation active des apprenant.es": [
    "RCNUM POST-OBLIGATOIRE 5.1.1 : Utiliser les technologies numériques pour favoriser l'engagement actif et créatif des apprenant.es sur un sujet donné",
    "RCNUM POST-OBLIGATOIRE 5.1.2 : Promouvoir les capacités/compétences transversales (réflexion, créativité, communication, collaboration, planification, ...)",
    "RCNUM POST-OBLIGATOIRE 5.1.3 : Favoriser des pédagogies actives, collaboratives et ouvertes (projet, résolution de problèmes,enquêtes scientifiques...)",
    "RCNUM POST-OBLIGATOIRE 5.1.4 : Soutenir une autonomie dans l'apprentissage et les compétences numériques attendues dans les voies d'études suivantes"],

  "Accessibilité et inclusion": [
    "RCNUM POST-OBLIGATOIRE 5.2.1 : Veiller à ce que chaque apprenant.e, y compris lors de besoins éducatifs particuliers (moteurs,cognitifs, sensoriels...), puisse avoir accès aux apprentissages (contenus, activités,autoévaluation...), grâce à des médias, outils ou environnements numériques adaptés",
    "RCNUM POST-OBLIGATOIRE 5.2.2 : Utiliser les potentialités de collaboration que les outils numériques offrent pour améliorer le partenariat avec les autres professionnel.les concerné.es, le cas échéant",
    "RCNUM POST-OBLIGATOIRE 5.2.3 : Veiller à ce que chaque apprenant.e, y compris lors de besoins éducatifs particuliers, développe des compétences dans le domaine de l'éducation numérique",
    "RCNUM POST-OBLIGATOIRE 5.2.4 : Identifier les soutiens à solliciter auprès des institutions ou les formations continues à suivre pour permettre l'accessibilité et l'inclusion, en tenant compte des contraintes temporelles, matérielles, technologiques, pédagogiques..."],

  "Différenciation et adaptation": [
    "RCNUM POST-OBLIGATOIRE 5.3.1 : Varier les environnements numériques d'apprentissage pour tendre vers une réponse aux divers besoins et intérêts des apprenant.es tout en maintenant des objectifs d'apprentissage définis et planifiés",
    "RCNUM POST-OBLIGATOIRE 5.3.2 : Adapter l'enseignement (niveaux, vitesses, parcours...) en différenciant les médias, outils, et/ou environnements numériques proposés tout en garantissant l'alignement pédagogique",
    "RCNUM POST-OBLIGATOIRE 5.3.3 : Mettre en œuvre des pratiques pédago-numériques et accompagner des pratiques d'apprentissage en évitant des écueils potentiels sur le processus d'apprentissage (p. ex. : distraction, délégation de l'effort cognitif, fatigabilité…)"],

  "Promotion de la littératie numérique": [
    "RCNUM POST-OBLIGATOIRE 6.1.1 : Rechercher et sélectionner les données, l'information et les contenus numériques",
    "RCNUM POST-OBLIGATOIRE 6.1.2 : Évaluer les données, les informations et les contenus numériques",
    "RCNUM POST-OBLIGATOIRE 6.1.3 : Gérer les données, les informations et les contenus numériques",
    "RCNUM POST-OBLIGATOIRE 6.1.4 : Faire preuve d'une lecture critique des données, de l'information et des contenus numériques, en saisissant les enjeux individuels, sociétaux, politiques, économiques et culturels associés"],

  "Soutien à la communication et la collaboration": [
    "RCNUM POST-OBLIGATOIRE 6.2.1 : Respecter les règles d'usage en matière de comportement social (nétiquette, charte locale, …)",
    "RCNUM POST-OBLIGATOIRE 6.2.2 : Communiquer via des technologies numériques",
    "RCNUM POST-OBLIGATOIRE 6.2.3 : Gérer son identité numérique",
    "RCNUM POST-OBLIGATOIRE 6.2.4 : Partager de l'information et des contenus via des technologies numériques",
    "RCNUM POST-OBLIGATOIRE 6.2.5 : Collaborer via des technologies numériques",
    "RCNUM POST-OBLIGATOIRE 6.2.6 : Évaluer la pertinence d'un usage de technologies numériques pour la communication et la collaboration",
    "RCNUM POST-OBLIGATOIRE 6.2.7 : Questionner l'usage des technologies numériques en lien avec l'engagement citoyen"],

  "Accompagnement à la création de contenus numériques": [
    "RCNUM POST-OBLIGATOIRE 6.3.1 : Développer des contenus numériques",
    "RCNUM POST-OBLIGATOIRE 6.3.2 : Modifier des contenus numériques",
    "RCNUM POST-OBLIGATOIRE 6.3.3 : Concevoir des séquences d'instructions pour effectuer une tâche spécifique",
    "RCNUM POST-OBLIGATOIRE 6.3.4 : Respecter les licences et les copyrights",
    "RCNUM POST-OBLIGATOIRE 6.3.5 : Tenir compte des orientations induites par les choix technologiques sur le processus créatif"],

  "Sensibilisation à la sécurité": [
    "RCNUM POST-OBLIGATOIRE 6.4.1 : Assurer la maintenance des outils numériques (entretien des périphériques, mise à jour régulière, …)",
    "RCNUM POST-OBLIGATOIRE 6.4.2 : Protéger les données personnelles (les siennes et celles d'autrui) ainsi que la vie privée (différence entre données sensibles et personnelles, stockage, …)",
    "RCNUM POST-OBLIGATOIRE 6.4.3 : Veiller à la santé et au bien-être (protection de la personnalité, consommation raisonnée, environnement de travail, posture, …)",
    "RCNUM POST-OBLIGATOIRE 6.4.4 : S'engager pour le développement durable (impact environnemental des pratiques, choix et renouvellement/cycle de vie du matériel, …)"],

  "Encouragement à la résolution de problèmes": [
    "RCNUM POST-OBLIGATOIRE 6.5.1 : Résoudre des problèmes techniques",
    "RCNUM POST-OBLIGATOIRE 6.5.2 : Identifier les manques en termes de compétences numériques",
    "RCNUM POST-OBLIGATOIRE 6.5.3 : Reconnaitre les besoins et l'adéquation des réponses technologiques",
    "RCNUM POST-OBLIGATOIRE 6.5.4 : Utiliser les technologies numériques de manière créative"]
};

export const RCNumObligatoireActivities = {
  "Citoyenneté numérique et dimension critique": [
    "RCNUM OBLIGATOIRE 1.0.1 : Gérer son identité numérique en tant qu'agent.e de la fonction publique",
    "RCNUM OBLIGATOIRE 1.0.2 : Manipuler divers environnements numériques, notamment des solutions libres",
    "RCNUM OBLIGATOIRE 1.0.3 : Identifier et tenir compte des enjeux de société et en particulier de la numérisation des phénomènes scolaires",
    "RCNUM OBLIGATOIRE 1.0.4 : Choisir les environnements numériques en étant conscients des intérêts en jeu"],

  "Communication professionnelle": [
    "RCNUM OBLIGATOIRE 1.1.1 : Sélectionner et utiliser des outils/environnements de communication numérique ainsi que des pratiques de communication adaptés aux différent.es partenaires",
    "RCNUM OBLIGATOIRE 1.1.2 : Contribuer au développement et à l'amélioration des stratégies de communication organisationnelles"],

  "Collaboration professionnelle": [
    "RCNUM OBLIGATOIRE 1.2.1 : Sélectionner les technologies numériques pour collaborer avec d'autres enseignant.es, partager et échanger des connaissances, des expériences et des pratiques pédagogiques pertinentes, innovantes et collaboratives",
    "RCNUM OBLIGATOIRE 1.2.2 : Participer à la production et la diffusion des ressources éducatives libres (RELs)",
    "RCNUM OBLIGATOIRE 1.2.3 : Mutualiser des pratiques pédago-numériques",
    "RCNUM OBLIGATOIRE 1.2.4 : Effectuer une curation de contenus"],

  "Pratique réflexive professionnelle": [
    "RCNUM OBLIGATOIRE 1.3.1 : Développer activement ses propres pratiques techno-pédagogiques et celles de sa communauté pédagogique",
    "RCNUM OBLIGATOIRE 1.3.2 : Questionner ses pratiques professionnelles numériques",
    "RCNUM OBLIGATOIRE 1.3.3 : Co-construire de nouvelles connaissances techno-pédagogiques dans des communautés de pratiques"],

  "Formation continue au numérique": [
    "RCNUM OBLIGATOIRE 1.3.1 : Sélectionner les sources et les ressources numériques pour le développement professionnel continu",
    "RCNUM OBLIGATOIRE 1.3.2 : Effectuer une veille techno-pédagogiques par exemple en s'engageant dans des réseaux professionnels",
    "RCNUM OBLIGATOIRE 1.3.3 : Développer une culture numérique en continu"],

  "Modalités": [
    "RCNUM OBLIGATOIRE 2.1.1 : Appréhender différentes modalités de travail avec des ressources pédagogiques pour identifier, évaluer et sélectionner des ressources existantes",
    "RCNUM OBLIGATOIRE 2.1.2 : Appréhender différentes modalités de travail avec des ressources pédagogiques pour modifier et compléter des ressources existantes",
    "RCNUM OBLIGATOIRE 2.1.3 : Appréhender différentes modalités de travail avec des ressources pédagogiques pour créer ou co-créer de nouvelles ressources",
    "RCNUM OBLIGATOIRE 2.1.4 : Appréhender différentes modalités de travail avec des ressources pédagogiques pour partager ses propres ressources"],

  "Apports": [
    "RCNUM OBLIGATOIRE 2.2.1 : Identifier les apports possibles de l'usage de ressources pédagogiques pour la construction et la transformation de connaissances disciplinaires",
    "RCNUM OBLIGATOIRE 2.2.2 : Identifier les apports possibles de l'usage de ressources pédagogiques pour le développement de capacités transversales"],

  "Conditions": [
    "RCNUM OBLIGATOIRE 2.3.1 : Dans le travail avec des ressources pédagogiques, prendre en considération les caractéristiques des ressources, telles que leur accessibilité, leur ergonomie ou leur affordance",
    "RCNUM OBLIGATOIRE 2.3.2 : Dans le travail avec des ressources pédagogiques, prendre en considération les conditions pédagogiques dans lesquelles on souhaite les utiliser, notamment le contexte, l'approche pédagogique, les objectifs d'apprentissage et le groupe d'apprenant.es"],

  "Normes légales": [
    "RCNUM OBLIGATOIRE 2.4.1 : Organiser le contenu numérique et le mettre à la disposition des apprenant.es, des parents et des autres enseignant.es",
    "RCNUM OBLIGATOIRE 2.4.2 : Protéger efficacement les contenus numériques sensibles",
    "RCNUM OBLIGATOIRE 2.4.3 : Respecter les règles de confidentialité et de droits d'auteur.es",
    "RCNUM OBLIGATOIRE 2.4.4 : Favoriser l'utilisation et la création de licences ouvertes et de ressources éducatives libres, y compris leur attribution appropriée"],

  "Enseignement avec des technologies numériques": [
    "RCNUM OBLIGATOIRE 3.1.1 : Planifier et mettre en œuvre des dispositifs et des ressources numériques dans l'enseignement, afin d'améliorer l'efficacité des interventions pédagogiques",
    "RCNUM OBLIGATOIRE 3.1.2 : Gérer et orchestrer de façon appropriée les interventions d'enseignement avec le numérique",
    "RCNUM OBLIGATOIRE 3.1.3 : Expérimenter et développer de nouveaux formats et méthodes pédagogiques pour l'enseignement"],

  "Soutien à l'apprentissage": [
    "RCNUM OBLIGATOIRE 3.2.1 : Utilisation des technologies et services numériques pour améliorer l'interaction avec les apprenant.es, individuellement et collectivement, à l'intérieur et à l'extérieur de la session d'apprentissage",
    "RCNUM OBLIGATOIRE 3.2.2 : Utilisation des technologies et services numériques pour offrir des formats d'accompagnement opportuns et ciblés",
    "RCNUM OBLIGATOIRE 3.2.3 : Utilisation des technologies et services numériques pour favoriser et améliorer la collaboration entre les apprenant.es",
    "RCNUM OBLIGATOIRE 3.2.4 : Utilisation des technologies et services numériques pour Soutenir les processus d'apprentissage autorégulés, permettre aux apprenant.es de planifier, de suivre et de réfléchir sur leur propre apprentissage, de fournir des preuves de progrès",
  ],

  "Enseignement et didactique": [
    "RCNUM OBLIGATOIRE 3.3.1 : Planifier et mettre en oeuvre une séquence d'enseignement mobilisant des technologies adaptées à la construction des savoirs disciplinaires",
    "RCNUM OBLIGATOIRE 3.3.2 : Identifier les possibilités et limites des outils dans la réalisation de tâches inscrites dans une discipline scolaire",
    "RCNUM OBLIGATOIRE 3.3.3 : Connaître les effets des outils numériques sur la construction des savoirs visés et les processus de conceptualisation"],

  "Enseigner la science informatique": [
    "RCNUM OBLIGATOIRE 3.4.1 : Planifier et mettre en oeuvre une séquence d'enseignement-apprentissage permettant l'acquisition de connaissances et de compétences en science informatique (algorithmique et programmation ; information et données ; machines, systèmes et réseaux ; pensée informatique) et la mise en évidence de ses enjeux sociaux",
    "RCNUM OBLIGATOIRE 3.4.2 : Identifier les difficultés ou compréhensions erronées courantes des apprenant.es ainsi que des démarches pour les dépasser",
    "RCNUM OBLIGATOIRE 3.4.3 : Implémenter des stratégies permettant le développement de compétences de lecture, de compréhension et de production d'un programme"],

  "Enseigner la littératie médiatique": [
    "RCNUM OBLIGATOIRE 3.5.1 : Planifier et mettre en oeuvre une séquence d'enseignement-apprentissage permettant le développement de la littératie médiatique",
    "RCNUM OBLIGATOIRE 3.5.2 : Implémenter des stratégies permettant le développement de l'esprit critique et sélectif face aux médias et de la capacité à analyser, évaluer et produire des contenus"],

  "Développer les enjeux sociaux du numérique": [
    "RCNUM OBLIGATOIRE 3.6.1 : PPlanifier et mettre en oeuvre une séquence d'enseignement-apprentissage permettant d'aborder les enjeux sociaux du numérique",
    "RCNUM OBLIGATOIRE 3.6.2 : Implémenter des stratégies permettant d'évaluer les impacts sociaux de la numérisation, notamment la question de la dématérialisation, les tensions entre liberté individuelle et sécurité, la gestion des ressources dans un monde numérique, la délégation de décisions humaines à des algorithmes, etc."],

  "Stratégies d'évaluation": [
    "RCNUM OBLIGATOIRE 4.1.1 : Utiliser les technologies numériques pour l'évaluation diagnostique, formative et sommative",
    "RCNUM OBLIGATOIRE 4.1.2 : Diversifier les formats et les approches d'évaluation, en adéquation avec les besoins des apprenant.es",
    "RCNUM OBLIGATOIRE 4.1.3 : Exploiter les technologies numériques pour évaluer les capacités transversales",
    "RCNUM OBLIGATOIRE 4.1.4 : Garantir un cadre éthique (transparence, clarté, but, durée...) dans la collecte et la gestion des données issues de processus d'évaluation",
    "RCNUM OBLIGATOIRE 4.1.5 : Faire preuve d'ouverture pour d'éventuelles nouvelles formes d'évaluation",
    "RCNUM OBLIGATOIRE 4.1.6 : Mobiliser des stratégies permettant d'évaluer l'acquisition de compétences et de connaissances par les apprenant.es dans les trois domaines de l'éducation numérique (médias, usages, science informatique)"],

  "Analyse des résultats": [
    "RCNUM OBLIGATOIRE 4.2.1 : Générer, sélectionner, analyser de manière critique et interpréter les données numériques sur l'activité, les performances et les progrès des apprenant.es, afin d'éclairer l'enseignement et l'apprentissage",
    "RCNUM OBLIGATOIRE 4.2.2 : Exploiter avec discernement les capacités des technologies numériques à documenter les singularités et les groupes ainsi qu'à permettre les comparaisons"],

  "Rétroaction": [
    "RCNUM OBLIGATOIRE 4.3.1 : Utiliser les technologies numériques pour fournir une rétroaction fréquente, ciblée et opportune aux apprenant.es",
    "RCNUM OBLIGATOIRE 4.3.2 : Permettre aux apprenant.es et aux parents de comprendre les résultats fournis par les technologies numériques et de les utiliser pour la prise de décision",
    "RCNUM OBLIGATOIRE 4.3.3 : Identifier les données qui peuvent et méritent d'être transmises à des partenaires pédago-thérapeutiques à des fins d'accompagnement personnalisé des apprenant.es"],

  "Réguler son enseignement": [
    "RCNUM OBLIGATOIRE 4.3.1 : Adapter les stratégies d'enseignement en conséquence et apporter un soutien ciblé, sur la base des résultats générés par les technologies numériques utilisées",
    "RCNUM OBLIGATOIRE 4.3.2 : Porter un regard critique sur un traitement automatisé des données d'évaluation",
    "RCNUM OBLIGATOIRE 4.3.3 : Identifier les besoins de l'enseignant.e dans l'exercice de sa profession sur la base des évaluations menées"],

  "Accessibilité et inclusion": [
    "RCNUM OBLIGATOIRE 5.1.1 : Veiller à ce que tous.toutes les apprenant.es, y compris ceux.celles qui ont des besoins éducatifs particuliers (moteurs, cognitifs, sensoriels...), aient accès aux apprentissages (contenus, activités, évaluation...), grâce à des médias, outils ou environnements numériques adaptés, en partenariat avec les autres professionnel.les le cas échéant",
    "RCNUM OBLIGATOIRE 5.1.2 : Veiller à ce que tous.toutes les apprenant.es, y compris ceux.celles qui ont des besoins éducatifs particuliers, développent des compétences dans le domaine de l'éducation numérique"],

  "Différenciation et adaptation": [
    "RCNUM OBLIGATOIRE 5.2.1 : Varier les environnements numériques d'apprentissage pour tendre vers une réponse aux divers besoins et intérêts des apprenant.es",
    "RCNUM OBLIGATOIRE 5.2.2 : Adapter l'enseignement (niveaux, vitesses, parcours...) en différenciant les médias, outils, et/ou environnements numériques proposés"],

  "Mobilisation active des apprenant.es": [
    "RCNUM OBLIGATOIRE 5.3.1 : Utiliser les technologies numériques pour favoriser l'engagement actif et créatif des apprenant.es sur un sujet donné",
    "RCNUM OBLIGATOIRE 5.3.2 : Promouvoir les capacités transversales (réflexion, expression créative, communication...) à l'aide de technologies numériques",
    "RCNUM OBLIGATOIRE 5.3.3 : Favoriser des pédagogies actives, collaboratives et ouvertes (projet, résolution de problèmes, enquêtes scientifiques...) à l'aide de technologies numériques"],

  "Médias": [
    "RCNUM OBLIGATOIRE 6.1.1 : Mettre en oeuvre des activités mettant l'accent sur les enjeux citoyens liés à l'utilisation des outils de création et de communication numériques (choix approprié de l'outil, sécurité, protection de la personnalité, des droits d'auteurs et des données, etc.)",
    "RCNUM OBLIGATOIRE 6.1.2 : Favoriser l'utilisation des médias dans les activités afin d'amener les apprenant.es à s'informer de manière éclairée et se familiariser avec la place de chaque média aux échelles individuelle, sociétale, économique, politique et culturelle",
    "RCNUM OBLIGATOIRE 6.1.3 : Intégrer des activités qui requièrent des apprenant.es une définition explicite de leurs besoins, une recherche, une évaluation, un choix et un traitement adéquats de l'information et de ses sources"],

  "Science informatique": [
    "RCNUM OBLIGATOIRE 6.2.1 : Mettre en oeuvre des activités permettant aux apprenant.es de développer leur compréhension des impacts de l'informatique sur l'homme et la société ainsi que leur capacité à débattre de ces questions avec un éclairage scientifique",
    "RCNUM OBLIGATOIRE 6.2.2 : Intégrer des activités permettant aux apprenant.es d'aborder des problèmes de manière structurée, de les décomposer puis de les formuler en une liste d'étapes ayant pour but de les résoudre de manière systématique, pour finalement les mettre en oeuvre en utilisant un langage de programmation",
    "RCNUM OBLIGATOIRE 6.2.3 : Favoriser la compréhension chez les apprenant.es de la manière dont tous types de données sont représentées et stockées dans un ordinateur, puis les familiariser avec leur analyse et l'interprétation de résultats",
    "RCNUM OBLIGATOIRE 6.2.4 : Proposer des activités permettant aux apprenant.es de construire une représentation d'un ordinateur, d'une machine ou d'un réseau, de ses composants et de leur fonctionnement, puis de décrire les principes et enjeux de sécurité qui y sont relatifs"],

  "Usages": [
    "RCNUM OBLIGATOIRE 6.3.1 : Développer des pratiques citoyennes pour soutenir la prévention (hyperconnectivité, cyberharcèlement, ...) s'appliquant aux contenus numériques, à leur utilisation et leur production ; contribuer à l'éducation au développement durable (EDD) par la compréhension, entre autres, des coûts énergétiques induits par les usages numériques (stockage, téléchargement ...)",
    "RCNUM OBLIGATOIRE 6.3.2 : Proposer aux apprenant.es des activités permettant d'acquérir des savoirs, des compétences et des habiletés de base sur le matériel, les logiciels et les réseaux numériques pour une utilisation efficiente des outils",
    "RCNUM OBLIGATOIRE 6.3.3 : Intégrer des activités scolaires qui facilitent la recherche de contenus et d'informations et soutiennent les apprentissages",
    "RCNUM OBLIGATOIRE 6.3.4 : Mettre en oeuvre des activités scolaires qui favorisent le réinvestissement et l'expression dans le cadre de création de contenus, de productions numériques et/ou médiatiques, qui mobilisent les usages des outils numériques pour la communication et la collaboration"]
};

export const traineeProficiencyLevels = [
  "Awareness (A1)",
  "Exploration (A2)",
  "Integration (B1)",
  "Expertise (B2)",
  "Leadership (C1)",
  "Innovation (C2)"
];

export const studentCompetenceAreas = {
  "DIGCOMP": [
    "Information and data literacy",
    "Communication and collaboration",
    "Digital content creation",
    "Safety",
    "Problem solving",
  ],
  "PER EN": [
    "Medias",
    "Science informatique",
    "Usages"
  ],
  "LEHRPLAN MI": [
    "Medien",
    "Informatik",
  ]
};

export const traineeCompetenceAreas = {
  'DIGCOMPEDU': [
    "Professional Engagement",
    "Digital Resources",
    "Teaching and Learning",
    "Assessment",
    "Empowering Learners",
    "Facilitating Learners Digital Competence"
  ],

  'RCNUM OBLIGATOIRE': [
    "Engagement professionnel",
    "Ressources pédagogiques",
    "Enseignement et apprentissage",
    "Evaluation",
    "Autonomie des apprenants",
    "Développement des compétences numériques des apprenant.es"
  ],

  'RCNUM POST-OBLIGATOIRE': [
    "Engagement professionnel",
    "Ressources pédagogiques",
    "Enseignement et apprentissage",
    "Evaluation",
    "Autonomie des apprenants",
    "Développement des compétences numériques des apprenant.es"
  ],
  'ESCO': [
    "DigCompSkills",
    "DigitalSkills"
  ],
};


export const digCompCompetences = {

  "Information and data literacy":
    ["BROWSING, SEARCHING AND FILTERING DATA, INFORMATION AND DIGITAL CONTENT",
      "EVALUATING DATA, INFORMATION AND DIGITAL CONTENT",
      "MANAGING DATA, INFORMATION AND DIGITAL CONTENT"],
  "Communication and collaboration":
    ["INTERACTING THROUGH DIGITAL TECHNOLOGIES",
      "SHARING THROUGH DIGITAL TECHNOLOGIES",
      "ENGAGING IN CITIZENSHIP THROUGH DIGITAL TECHNOLOGIES",
      "COLLABORATING THROUGH DIGITAL TECHNOLOGIES",
      "NETIQUETTE",
      "MANAGING DIGITAL IDENTITY"],

  "Digital content creation": [
    "DEVELOPING CONTENT",
    "INTEGRATING AND RE-ELABORATING DIGITAL CONTENT",
    "COPYRIGHT AND LICENSES",
    "PROGRAMMING"],

  "Safety": [
    "PROTECTING DEVICES",
    "PROTECTING PERSONAL DATA AND PRIVACY",
    "PROTECTING HEALTH AND WELL-BEING",
    "PROTECTING THE ENVIRONMENT"],

  "Problem solving": [
    "SOLVING TECHNICAL PROBLEMS",
    "IDENTIFYING NEEDS AND TECHNOLOGICAL RESPONSES",
    "CREATIVELY USING DIGITAL TECHNOLOGY",
    "IDENTIFYING DIGITAL COMPETENCE GAPS"],
};

export const digCompActivities = {
  "BROWSING, SEARCHING AND FILTERING DATA, INFORMATION AND DIGITAL CONTENT": {
    "Knowledge": [
      "DIGCOMP 1 : Knows that some online content in search result may not be open access or freely available and may require a fee or signing up for a service in order to access it",
      "DIGCOMP 2 : Aware that online content that is available to users at no monetary cost is often paid for by advertising or by selling the user's data",
      "DIGCOMP 3 : Aware that search results, social media activity streams and content recommendations on the internet are influenced by a range of factors. These factors include the search terms used, the context (e.g. geographical location), the device (e.g. laptop or mobile phone), local regulations (which sometimes dictate what can or cannot be shown), the behaviour of other users (e.g. trending searches or recommendations) and the user's past online behaviour across the internet",
      "DIGCOMP 4 : Aware that search engines, social media and content platforms often use AI algorithms to generate responses that are adapted to the individual user (e.g. users continue to see similar results or content). This is often referred to as “personalisation” (AI)",
      "DIGCOMP 5 : Aware that AI algorithms work in ways that are usually not visible or easily understood by users. This is often referred to as “black box” decision-making as it may be impossible to trace back how and why an algorithm makes specific suggestions or predictions (AI)"],
    "Skill": [
      "DIGCOMP 6 : Can choose the search engine that most likely meets one's information needs as different search engines can provide different results even for the same query",
      "DIGCOMP 7 : Knows how to improve search results by using a search engine's advanced features (e.g. specifying exact phrase, language, region, date last updated)",
      "DIGCOMP 8 : Knows how to formulate search queries to achieve the desired output when interacting with conversational agents or smart speakers (e.g. Siri, Alexa, Cortana, Google Assistant), e.g. recognising that, for the system to be able to respond as required, the query must be unambiguous and spoken clearly so that the system can respond..(AI)",
      "DIGCOMP 9 : Can make use of information presented as hyperlinks, in non-textual form (e.g. flowcharts, knowledge maps) and in dynamic representations (e.g. data)",
      "DIGCOMP 10 : Develops effective search methods for personal purposes (e.g. to browse a list of most popular films) and professional purposes (e.g. to find appropriate job advertisements)",
      "DIGCOMP 11 : Knows how to handle information overload and “infodemic” (i.e. increase of false or misleading information during a disease outbreak) by adapting personal search methods and strategies"],
    "Attitude": [
      "DIGCOMP 12 : Intentionally avoids distractions and aims to avoid information overload when accessing and navigating information, data and content",
      "DIGCOMP 13 : Values tools designed to protect search privacy and other rights of users (e.g. browsers such as DuckDuckGo)",
      "DIGCOMP 14 : Weighs the benefits and disadvantages of using AI-driven search engines (e.g. while they might help users find the desired information, they may compromise privacy and personal data, or subject the user to commercial interests) (AI)",
      "DIGCOMP 15 : Concerned that much online information and content may not be accessible to people with a disability, for example to users who rely on screen reader technologies to read aloud the content of a web page (DA)"]
  },
  "EVALUATING DATA, INFORMATION AND DIGITAL CONTENT": {
    "Knowledge": [
      "DIGCOMP 16 : Aware that online environments contain all types of information and content including misinformation and disinformation, and even if a topic is widely reported it does not necessarily mean it is accurate",
      "DIGCOMP 17 : Understands the difference between disinformation (false information with the intent to deceive people) and misinformation (false information regardless of intent to deceive or mislead people)",
      "DIGCOMP 18 : Knows the importance of identifying who is behind information found on the internet (e.g. on social media) and verifying it by checking multiple sources, to help recognise and understand point of view or bias behind particular information and data sources",
      "DIGCOMP 19 : Aware of potential information biases caused by various factors (e.g. data, algorithms, editorial choices, censorship, one's own personal limitations)",
      "DIGCOMP 20 : Knows that the term “deep-fakes” refers to AI-generated images, videos or audio recordings of events or persons that did not really happen (e.g. speeches by politicians, celebrity faces on pornographic imagery). They may be impossible to distinguish from the real thing (AI)",
      "DIGCOMP 21 : Aware that AI algorithms might not be configured to provide only the information that the user wants; they might also embody a commercial or political message (e.g. to encourage users to stay on the site, to watch or buy something particular, to share specific opinions). This can also have negative consequences (e.g. reproducing stereotypes, sharing misinformation) (AI)",
      "DIGCOMP 22 : Aware that the data, on which AI depends, may include biases. If so, these biases can become automated and worsened by the use of AI. For example, search results about occupation may include stereotypes about male or female jobs (e.g. male bus drivers, female sales persons) (AI)"],
    "Skill": [
      "DIGCOMP 23 : Carefully considers the top/first search results in both text-based and audio searches, as they may reflect commercial and other interests rather than be the most appropriate results for the query",
      "DIGCOMP 24 : Knows how to differentiate sponsored content from other content online (e.g. recognising advertisements and marketing messages on social media or search engines) even if it is not marked as sponsored",
      "DIGCOMP 25 : Knows how to analyse and critically evaluate search results and social media activity streams, to identify their origins, to distinguish fact-reporting from opinion, and to determine whether outputs are truthful or have other limitations (e.g. economic, political, religious interests)",
      "DIGCOMP 26 : Knows how to find the author or the source of the information, to verify whether it is credible (e.g. an expert or authority in a relevant discipline)",
      "DIGCOMP 27 : Able to recognise that some AI algorithms may reinforce existing views in digital environments by creating “echo chambers” or “filter bubbles” (e.g. if a social media stream favours a particular political ideology, additional recommendations can reinforce that ideology without exposing it to opposing arguments) (AI)"],
    "Attitude": [
      "DIGCOMP 28 : Inclined to ask critical questions in order to evaluate the quality of online information, and concerned about purposes behind spreading and amplifying disinformation",
      "DIGCOMP 29 : Willing to fact-check a piece of information and assess its accuracy, reliability and authority, while preferring primary sources over secondary sources of information where possible",
      "DIGCOMP 30 : Carefully considers the possible outcome before clicking a link. Some links (e.g. compelling titles) could be “clickbait” that takes the user to sponsored or unwanted content (e.g. pornography)"
    ]
  },
  "MANAGING DATA, INFORMATION AND DIGITAL CONTENT": {
    "Knowledge": [
      "DIGCOMP 31 : Aware that many applications on the internet and mobile phones collect and process data (personal data, behavioural data and contextual data) that the user can access or retrieve, for example, to monitor their activities online (e.g. clicks in social media, searches on Google) and offline (e.g. daily steps, bus rides on public transport)",
      "DIGCOMP 32 : Aware that for data (e.g. numbers, text, images, sounds) to be processed by a program, they have to be first properly digitised (i.e. digitally encoded)",
      "DIGCOMP 33 : Knows that data collected and processed, for example by online systems, can be used to recognise patterns (e.g. repetitions) in new data (i.e. other images, sounds, mouse clicks, online behaviours) to further optimise and personalise online services (e.g. advertisements)",
      "DIGCOMP 34 : Aware that sensors used in many digital technologies and applications (e.g. facial tracking cameras, virtual assistants, wearable technologies, mobile phones, smart devices) generate large amounts of data, including personal data, that can be used to train an AI system (AI)",
      "DIGCOMP 35 : Knows that open data repositories exist where anyone can get data to support some problem solving activities (e.g. citizens can use open data to generate thematic maps or other digital content)",
    ],
    "Skill": [
      "DIGCOMP 36 : Knows how to collect digital data using basic tools such as online forms, and present them in an accessible way (e.g. using headers in tables)",
      "DIGCOMP 37 : Can apply basic statistical procedures to data in a structured environment (e.g. spreadsheet) to produce graphs and other visualisations (e.g. histograms, bar charts, pie charts)",
      "DIGCOMP 38 : Knows how to interact with dynamic data visualisation and can manipulate dynamic graphs of interest (e.g. as provided by Eurostat, government websites)",
      "DIGCOMP 39 : Can differentiate between different types of storage locations (local devices, local network, cloud) that are the most appropriate to use (e.g. data on the cloud is available anytime and from anywhere, but has implications for access time)",
      "DIGCOMP 40 : Can use data tools (e.g. databases, data mining, analysis software) designed to manage and organise complex information, to support decision-making and solving problems",
    ],
    "Attitude": [
      "DIGCOMP 41 : Considers transparency when manipulating and presenting data to ensure reliability, and spots data that are expressed with underlying motives (e.g. unethical, profit, manipulation) or in misleading ways",
      "DIGCOMP 42 : Watchful of accuracy when evaluating sophisticated representations of data (e.g. tables or visualisations as they could be used to mislead one's judgement by trying to give a false sense of objectivity"
    ]
  },
  "INTERACTING THROUGH DIGITAL TECHNOLOGIES": {
    "Knowledge": [
      "DIGCOMP 43 : Knows that many communication services (e.g. instant messaging) and social media are free of charge because they are partly paid for by advertising and monetising user data",
      "DIGCOMP 44 : Aware that many communication services and digital environments (e.g. social media) use mechanisms such as nudging, gamification and manipulation to influence user behaviour",
      "DIGCOMP 45 : Aware which communication tools and services (e.g. phone, email, video conference, social network, podcast) are appropriate in specific circumstances (e.g. synchronous, asynchronous), depending on the audience, context and purpose of the communication. Aware that some tools and services also provide an accessibility statement (DA)",
      "DIGCOMP 46 : Aware of the need to formulate messages in digital environments so that they are easily understandable by the targeted audience or the recipient"
    ],
    "Skill": [
      "DIGCOMP 47 : Knows how to use a variety of videoconferencing features (e.g. moderating a session, recording audio and video)",
      "DIGCOMP 48 : Able to achieve effective communication in asynchronous (non-simultaneous) mode using digital tools (e.g. for reporting and briefing, sharing ideas, giving feedback and advice, scheduling meetings, communicating milestones)",
      "DIGCOMP 49 : Knows how to use digital tools for informal communication with colleagues in order to develop and maintain social relations (e.g. to reproduce conversations such as those during face-to-face coffee breaks)",
      "DIGCOMP 50 : Knows how to identify signs that indicate whether one is communicating with a human or an AI-based conversational agent (e.g. when using text- or voice-based chatbots) (AI)",
      "DIGCOMP 51 : Able to interact and give feedback to the AI system (e.g. by giving user ratings, likes, tags to online content) to influence what it next recommends (e.g. to get more recommendations on similar movies that the user previously liked) (AI)",
      "DIGCOMP 52 : Considers the need to balance asynchronous and synchronous communication activities (e.g. to minimise video conferencing fatigue, to respect co-workers time and preferred working hours)"
    ],
    "Attitude": [
      "DIGCOMP 53 : Willing to listen to others and to engage in online conversations with confidence, clarity and reciprocity, both in personal and social contexts",
      "DIGCOMP 54 : Open to AI systems supporting humans to make informed decisions in accordance with their goals (e.g. users actively deciding whether to act upon a recommendation or not) (AI)",
      "DIGCOMP 55 : Willing to adapt an appropriate communication strategy depending on the situation and digital tool: verbal strategies (written, oral language), non-verbal strategies (body language, facial expressions, tone of voice), visuals strategies (signs, icons, illustrations) or mixed strategies"
    ]
  },
  "SHARING THROUGH DIGITAL TECHNOLOGIES": {
    "Knowledge": [
      "DIGCOMP 56 : Aware that everything that one shares publicly online (e.g. images, videos, sounds) can be used to train AI systems. For example, commercial software companies who develop AI facial recognition systems can use personal images shared online (e.g. family photographs) to train and improve the software’s capability to automatically recognise those persons in other images, which might not be desirable (e.g. might be a breach of privacy) (AI)",
      "DIGCOMP 57 : Knows the role and responsibilities of the online facilitator to structure and guide a discussion group (e.g. how to act as an intermediary when sharing information and digital content in digital environments)"
    ],
    "Skill": [
      "DIGCOMP 58 : Knows how to share digital content (e.g. pictures) across multiple devices (e.g. from smartphones to cloud services)",
      "DIGCOMP 59 : Knows how to share and show information from one's own device (e.g. show graphs from a laptop) to support a message being conveyed during a real time online session (e.g. video conference) (RW)",
      "DIGCOMP 60 : Able to select and restrict with whom the content is shared (e.g. giving access only to friends on social media, allowing only co-workers to read and comment on a text)",
      "DIGCOMP 61 : Knows how to curate content on content sharing platforms so as to add value for oneself and others (e.g. shares music playlists, shares comments on online services)",
      "DIGCOMP 62 : Knows how to acknowledge the original source and authors of shared content",
      "DIGCOMP 63 : Knows how to flag or report disinformation and misinformation to fact-checking organisations and to social media platforms in order to stop it spreading"
    ],
    "Attitude": [
      "DIGCOMP 64 : Willing to share expertise on the internet, for example through intervening in online forums, contributing to Wikipedia or through creating Open Educational Resources",
      "DIGCOMP 65 : Open towards sharing digital content that might be interesting and useful to others",
      "DIGCOMP 66 : Inclined not to share digital resources if not able to cite their author or source in an appropriate manner"
    ]
  },
  "ENGAGING IN CITIZENSHIP THROUGH DIGITAL TECHNOLOGIES": {
    "Knowledge": [
      "DIGCOMP 67 : Knows about different types of digital services on the internet: public ones (e.g. services to consult tax information or make an appointment in the health care centre), community-based services (e.g. knowledge repositories such as Wikipedia, map services such as Open Street Map, environmental monitoring services such as Sensor Community) and private services (e.g. e-commerce, online banking)",
      "DIGCOMP 68 : Knows that a secure electronic identification, (e.g. identity cards which contain digital certificates), enables citizens to increase safety when using online services provided by the government or by the private sector",
      "DIGCOMP 69 : Knows that all EU citizens have the right to not be subject to fully automated decision-making (e.g. if an automatic system refuses a credit application, the customer has the right to ask for the decision to be reviewed by a person) (AI)",
      "DIGCOMP 70 : Recognises that while the application of AI systems in many domains is usually uncontroversial (e.g. AI that helps avert climate change), AI that directly interacts with humans and takes decisions about their life can often be controversial (e.g. CV-sorting software for recruitment procedures, scoring of exams that may determine access to education) (AI)",
      "DIGCOMP 71 : Knows that AI per se is neither good nor bad. What determines whether the outcomes of an AI system are positive or negative for society are how the AI system is designed and used, by whom and for what purposes (AI)",
      "DIGCOMP 72 : Aware of civil society platforms on the internet that offer opportunities for citizens to participate in actions targeting global developments to reach sustainability goals on local, regional, national, European and international level",
      "DIGCOMP 73 : Aware of the role of traditional (e.g. newspapers, television) and new forms of media (e.g. social media, the internet) in democratic societies",
    ],
    "Skill": [
      "DIGCOMP 74 : Knows how to acquire certificates from a certification authority (CA) for the purpose of a secure electronic identification",
      "DIGCOMP 75 : Knows how to monitor public spending of local and national government (e.g. through open data on the government's website and open data portals)",
      "DIGCOMP 76 : Knows how to identify areas where AI can bring benefits to various aspects of everyday life. For example, in healthcare, AI might contribute to early diagnosis, while in agriculture, it might be used to detect pest infestations (AI)",
      "DIGCOMP 77 : Knows how to engage with others through digital technologies for the sustainable development of society (e.g. create opportunities for joint action across communities, sectors and regions with different interests in sustainability challenges) with an awareness of technology's potential for both inclusion/participation and exclusion",
    ],
    "Attitude": [
      "DIGCOMP 78 : Open to change one's own administrative routines and adopt digital procedures when dealing with government and public services",
      "DIGCOMP 79 : Readiness to contemplate ethical questions related to AI systems (e.g. in which contexts, such as sentencing criminals, should AI recommendations not be used without human intervention)? (AI)",
      "DIGCOMP 80 : Considers responsible and constructive attitudes on the internet as they are the foundation for human rights. Together with values such as respect for human dignity, freedom, democracy and equality",
      "DIGCOMP 81 : Proactive about using the internet and digital technologies to seek opportunities for constructive participation in democratic decision-making and civic activities (e.g. by participating in consultations organised by municipality, policy-makers, NGOs; signing a petition using a digital platform)",
    ]
  },
  "COLLABORATING THROUGH DIGITAL TECHNOLOGIES": {
    "Knowledge": [
      "DIGCOMP 82 : Aware of the advantages of using digital tools and technologies for remote collaborative processes (e.g. reduced commuting time, join specialised skills together regardless of location)",
      "DIGCOMP 83 : Understands that in order to co-create digital content with other people, good social skills (e.g. clear communication, ability to clarify misunderstandings) are important to compensate for the limitations of online communication"
    ],
    "Skill": [
      "DIGCOMP 84 : Knows how to use digital tools in a collaborative context to plan and share tasks and responsibilities within a group of friends, a family or a sport or work team (e.g. digital calendar, planners for trips and leisure activities)",
      "DIGCOMP 85 : Knows how to use digital tools to facilitate and improve collaborative processes, for example through shared visual boards and digital canvases (e.g. Mural, Miro, Padlet)",
      "DIGCOMP 86 : Knows how to engage collaboratively in a wiki (e.g. negotiate opening a new entry on a subject that is missing from Wikipedia to increase public knowledge)",
      "DIGCOMP 87 : Knows how to use digital tools and technologies in a remote working context for idea generation and co-creation of digital content (e.g. shared mind maps and whiteboards, polling tools) (RW)",
      "DIGCOMP 88 : Knows how to evaluate the advantages and disadvantages of digital applications for making collaboration effective (e.g. the use of online spaces for co-creation, shared project management tools)"
    ],
    "Attitude": [
      "DIGCOMP 89 : Encourages everyone to express their own opinions constructively when collaborating in digital environments",
      "DIGCOMP 90 : Acts in trustworthy ways to achieve group goals when engaging in co-construction of resources or knowledge",
      "DIGCOMP 91 : Inclined to use appropriate digital tools for fostering collaboration between the members of a team while, at the same time, ensuring digital accessibility (DA)"
    ]
  },
  "NETIQUETTE": {
    "Knowledge": [
      "DIGCOMP 92 : Aware of the meaning of non-verbal messages (e.g. smiley faces, emojis) used in digital environments (e.g. social media, instant messaging) and knowing that their use can culturally differ between countries and communities",
      "DIGCOMP 93 : Aware of the existence of some expected rules about one's behaviour when using digital technologies (e.g. using audio headsets instead of loudspeakers when taking calls in public places or listening to music)",
      "DIGCOMP 94 : Understands that inappropriate behaviours in digital environments (e.g. drunken, being overly intimate and other sexually explicit behaviour) can damage social and personal aspects of lives over a long term",
      "DIGCOMP 95 : Aware that adapting one's behaviour in digital environments depends on one's relationship with other participants (e.g. friends, co-workers, managers) and the purpose in which the communication takes place (e.g. to instruct, inform, persuade, order, entertain, inquire, socialise)",
      "DIGCOMP 96 : Aware of accessibility requirements when communicating in digital environments so that communication is inclusive and accessible for all users (e.g. for people with disabilities, older people, those with low literacy, speakers of another language) (DA)",
    ],
    "Skill": [
      "DIGCOMP 97 : Knows how to stop receiving unwanted disturbing messages or emails",
      "DIGCOMP 98 : Able to manage one's feelings when talking with other people on the internet",
      "DIGCOMP 99 : Knows how to recognise hostile or derogatory messages or activities online that attack certain individuals or groups of individuals (e.g. hate speech)",
      "DIGCOMP 100 : Can manage interactions and conversations in different socio-cultural contexts and domain-specific situations",
    ],
    "Attitude": [
      "DIGCOMP 101 : Believes that it is necessary to define and share rules within digital communities (e.g. explain codes of conduct for creating, sharing or posting content)",
      "DIGCOMP 102 : Inclined to adopt an empathic perspective in communication (e.g. being responsive to another person's emotions and experiences, negotiating disagreements to build and sustain fair and respectful relationships)",
      "DIGCOMP 103 : Open to and respectful of the views of people on the internet with different cultural affiliations, backgrounds, beliefs, values, opinions or personal circumstances; open to the perspectives of others even if they differ from one's own"
    ]
  },
  "MANAGING DIGITAL IDENTITY": {
    "Knowledge": [
      "DIGCOMP 104 : Aware that digital identity refers to (1) the method of authenticating a user on a website or an online service, and also to (2) a set of data identifying a user by means of tracing their digital activities, actions and contributions on the internet or digital devices (e.g. pages viewed, purchase history), personal data (e.g. name, username, profile data such as age, gender, hobbies) and context data (e.g. geographical location)",
      "DIGCOMP 105 : Aware that AI systems collect and process multiple types of user data (e.g. personal data, behavioural data and contextual data) to create user profiles which are then used, for example, to predict what the user might want to see or do next (e.g. offer advertisements, recommendations, services) (AI)",
      "DIGCOMP 106 : Knows that in the EU, one has the right to ask a website's or search engine's administrators to access personal data held about you (right of access), to update or correct them (right of rectification), or remove them (right of erasure, also known as the Right To Be Forgotten)",
      "DIGCOMP 107 : Aware that there are ways to limit and manage the tracking of one's activities on the internet, such as software features (e.g. private browsing, deletion of cookies) and privacy-enhancing tools and product/service features (e.g. custom consent for cookies, opting out of personalised ads)",
    ],
    "Skill": [
      "DIGCOMP 108 : Knows how to create and manage profiles in digital environments for personal purposes (e.g. civic participation, e-commerce, social media use) and professional purposes (e.g. create a profile on an online employment platform)",
      "DIGCOMP 109 : Knows how to adopt information and communication practices in order to build a positive online identity (e.g. by adopting healthy, safe and ethical behaviours, such as avoiding stereotypes and consumerism)",
      "DIGCOMP 110 : Able to conduct an individual or family name search in order to inspect one's own digital footprint in online environments (e.g. to detect any potentially troubling posts or images, to exercise one's legal rights)",
      "DIGCOMP 111 : Able to verify and modify what type of metadata (e.g. location, time) is included in pictures being shared in order to protect privacy",
      "DIGCOMP 112 : Knows what strategies to use in order to control, manage or delete data that is collected/curated by online systems (e.g. keeping track of services used, listing online accounts, deleting accounts that are not in use)",
      "DIGCOMP 113 : Knows how to modify user configurations (e.g. in apps, software, digital platforms) to enable, prevent or moderate the AI system tracking, collecting or analysing data (e.g. not allowing the mobile phone to track the user's location) (AI)",
    ],
    "Attitude": [
      "DIGCOMP 114 : Considers the benefits (e.g. fast authentication process, user preferences) and risks (e.g. having identities stolen, personal data exploited by third parties) when managing one or multiple digital identities across digital systems, apps and services",
      "DIGCOMP 115 : Inclined to check and select website cookies to be installed (e.g. accepting only technical cookies) when the website provides users with this option",
      "DIGCOMP 116 : Careful about keeping one's own and others' personal information private (e.g. vacations or birthday photos; religious or political comments)",
      "DIGCOMP 117 : Identifies both the positive and negative implications of the use of all data (collection, encoding and processing), but especially personal data, by AI driven digital technologies such as apps and online services (AI)",
    ]
  },
  "DEVELOPING CONTENT": {
    "Knowledge": [
      "DIGCOMP 118 : Knows that digital content exists in a digital form and that there are many different types of digital content (e.g. audio, image, text, video, applications) that are stored in various digital file formats",
      "DIGCOMP 119 : Knows that AI systems can be used to automatically create digital content (e.g. texts, news, essays, tweets, music, images) using existing digital content as its source. Such content may be difficult to distinguish from human creations (AI)",
      "DIGCOMP 120 : Aware that “digital accessibility” means ensuring that everyone, including people with disabilities, can use and navigate the internet. Digital accessibility includes accessible websites, digital files and documents, and other web-based applications (e.g. for online banking, accessing public services, and messaging and video-calling services) (DA)",
      "DIGCOMP 121 : Aware that virtual reality (VR) and augmented reality (AR) allow new ways to explore simulated environments and interactions within the digital and physical worlds",
    ],
    "Skill": [
      "DIGCOMP 122 : Can use tools and techniques to create accessible digital content (e.g. add ALT text to images, tables and graphs; create a proper and well-labelled document structure; use accessible fonts, colours, links) (DA)",
      "DIGCOMP 123 : Knows how to select the appropriate format for digital content according to its purpose (e.g. saving a document in an editable format vs one that cannot be modified but is easily printed)",
      "DIGCOMP 124 : Knows how to create digital content to support one's own ideas and opinions (e.g. to produce data representations such as interactive visualisations using basic datasets such as open government data)",
      "DIGCOMP 125 : Knows how to create digital content on open platforms (e.g. create and modify text in a wiki environment)",
      "DIGCOMP 126 : Knows how to use Internet of Things (IoT) and mobile devices to create digital content (e.g. use embedded cameras and microphones to produce photos or videos)",
    ],
    "Attitude": [
      "DIGCOMP 127 : Inclined to combine various types of digital content and data to better express facts or opinions for personal and professional use",
      "DIGCOMP 128 : Open to explore alternative pathways to find solutions to produce digital content",
      "DIGCOMP 129 : Inclined to follow official standards and guidelines (e.g. WCAG 2.1 and EN 301 549) to test the accessibility of a website, digital files, documents, e-mails or other web-based applications that one has created (DA)"
    ]
  },
  "INTEGRATING AND RE-ELABORATING DIGITAL CONTENT": {
    "Knowledge": [
      "DIGCOMP 130 : Aware that it is possible to integrate hardware (e.g. sensors, cables, motors) and software structures to develop programmable robots and other non-digital artefacts (e.g. Lego Mindstorms, Micro:bit, Raspberry Pi, EV3, Arduino, ROS)",
    ],
    "Skill": [
      "DIGCOMP 131 : Can create infographics and posters combining information, statistical content and visuals using available apps or software",
      "DIGCOMP 132 : Knows how to use tools and applications (e.g. add-ons, plug-ins, extensions) to enhance digital accessibility of digital content (e.g. adding captions in video players to a recorded presentation) (DA)",
      "DIGCOMP 133 : Knows how to integrate digital technologies, hardware and sensor data to create a new (digital or non-digital) artefact (e.g. makerspace and digital fabrication activities)",
      "DIGCOMP 134 : Knows how to incorporate AI edited/manipulated digital content in one's own work (e.g. incorporate AI generated melodies in one's own musical composition). This use of AI can be controversial as it raises questions about the role of AI in artworks, and for example, who should be credited (AI)",
    ],
    "Attitude": [
      "DIGCOMP 135 : Open to creating something new from existing digital content using iterative design processes ( e.g. create, test, analyse and refine ideas)",
      "DIGCOMP 136 : Inclined to help others to improve their digital content (e.g. through providing useful feedback)",
      "DIGCOMP 137 : Inclined to use available tools to verify whether images or videos have been modified (e.g. by deep-fake techniques)",
    ],
  },
  "COPYRIGHT AND LICENSES": {
    "Knowledge": [

      "DIGCOMP 138 : Knows that digital content, goods and services might be protected under intellectual property (IP) rights (e.g. copyright, trademarks, designs, patents)",
      "DIGCOMP 139 : Aware that the creation of digital content (e.g. pictures, texts, music) when original is considered protected by copyright as soon as it exists (automatic protection)",
      "DIGCOMP 140 : Aware that certain copyright exceptions exist (e.g. use for the purpose of illustration for teaching, for caricature, parody, pastiche, for quotation, private uses)",
      "DIGCOMP 141 : Knows different models of licensing software (e.g. proprietary, free and open-source software) and that some types of licences need to be renewed once the licence period expires",
      "DIGCOMP 142 : Aware of the legal limitations of using and sharing digital content (e.g. music, movies, books) and the possible consequences of illegal actions (e.g. sharing copyrighted content with others can give rise to legal sanctions)",
      "DIGCOMP 143 : Aware that mechanisms and methods to block or limit access to digital content exist (e.g. passwords, geo-blocking, Technical Protection Measures, TPM)"
    ],
    "Skill": [
      "DIGCOMP 144 : Able to identify and select digital content for downloading or uploading legally (e.g. public domain databases and tools, open licences)",
      "DIGCOMP 145 : Knows how to use and share digital content legally (e.g. checks the terms and conditions and licensing schemes available, such as the various types of Creative Commons) and knows how to assess whether limitations and copyright exceptions apply",
      "DIGCOMP 146 : Able to identify when uses of copyright-protected digital content fall under the scope of a copyright exception so that no prior consent is needed (e.g. teachers and students in the EU can use copyright protected content for the purpose of illustration for teaching)",
      "DIGCOMP 147 : Able to check and understand the right to use and/or re-use digital content created by a third party (e.g. knows about collective licencing schemes and contacts the relevant collective management organisations , understands the various Creative Commons licences)",
      "DIGCOMP 148 : Can choose the most suitable strategy, including the licensing, for the purpose of sharing and protecting one's own original creation (e.g. by registering it in an optional copyright deposit system; choosing open licences such as Creative Commons)",
    ],
    "Attitude": [
      "DIGCOMP 149 : Respectful of rights affecting others (e.g. ownership, contract terms), only using legal sources for downloading digital content (e.g. movies, music, books) and when relevant, opting for open-source software",
      "DIGCOMP 150 : Open to consider whether open licences or other licence schemes are more suitable when producing and publishing digital content and resources",
    ]
  },
  "PROGRAMMING": {
    "Knowledge": [
      "DIGCOMP 151 : Knows that computer programs are made of instructions, written according to strict rules in a programming language",
      "DIGCOMP 152 : Knows that programming languages provide structures that allow program instructions to be executed in sequence, repeatedly, or only under certain conditions, and to group them to define new instructions",
      "DIGCOMP 153 : Knows that programs are executed by computing devices/systems, that are able to automatically interpret and execute instructions",
      "DIGCOMP 154 : Knows that programs produce output data depending on input data, and that different inputs usually yield different outputs (e.g. a calculator will provide output 8 to the 3+5 input and output 15 to the 7+8 input)",
      "DIGCOMP 155 : Knows that, to produce its output, a program stores and manipulates data in the computer system that executes it, and that it sometimes behaves unexpectedly (e.g. faulty behaviour, malfunction, data leakage)",
      "DIGCOMP 156 : Knows that a program's blueprint is based on an algorithm, i.e. a step-wise method to produce an output from an input",
      "DIGCOMP 157 : Knows that algorithms, and consequently programs, are designed to help solve real life problems; input data models the known information about the problem, while output data provides information relevant to the problem's solution. There are different algorithms, and consequently programs, solving the same problem",
      "DIGCOMP 158 : Knows that any program requires time and space (hardware resources) to compute its output, depending on the input's size and/or problem's complexity",
      "DIGCOMP 159 : Knows that there are problems that cannot be solved exactly by any known algorithm in reasonable time, thus, in practice they are frequently dealt with by approximate solutions (e.g. DNA sequencing, data clustering, weather forecasting)"
    ],
    "Skill": [
      "DIGCOMP 160 : Knows how to combine a set of program blocks (e.g. as in the visual programming tool Scratch), in order to solve a problem",
      "DIGCOMP 161 : Knows how to detect issues in a sequence of instructions, and make changes to resolve them (e.g. to find an error in the program and correct it; to detect the reason why the execution time or output of the program is not as expected)",
      "DIGCOMP 162 : Able to identify input and output data in some simple programs",
      "DIGCOMP 163 : Given a program, being able to recognise the execution order of instructions, and how information is processed",
    ],
    "Attitude": [
      "DIGCOMP 164 : Willing to accept that algorithms, and hence programs, may not be perfect in solving the problem that they aim to address",
      "DIGCOMP 165 : Considers ethics (including but not limited to human agency and oversight, transparency, non-discrimination, accessibility, and biases and fairness) as one of the core pillars when developing or deploying AI systems (AI)",
    ]
  },
  "PROTECTING DEVICES": {
    "Knowledge": [
      "DIGCOMP 166 : Knows that using different strong passwords for different online services is a way to mitigate the negative effects of an account being compromised (e.g. hacked)",
      "DIGCOMP 167 : Knows about measures to protect devices (e.g. password, fingerprints, encryption) and prevent others (e.g. a thief, commercial organisation, government agency) from having access to all data",
      "DIGCOMP 168 : Knows about the importance of keeping the operating system and applications (e.g. browser) up-to-date, in order to fix security vulnerabilities and protect against malicious software (i.e. malware)",
      "DIGCOMP 169 : Knows that a firewall blocks certain kinds of network traffic, aiming to prevent different security risks (e.g. remote logins)",
      "DIGCOMP 170 : Aware of different types of risks in digital environments, such as identity theft (e.g. someone committing fraud or other crimes using another person's personal data), scams (e.g. financial scams where victims are tricked into sending money), malware attacks (e.g. ransomware)"
    ],
    "Skill": [
      "DIGCOMP 171 : Knows how to adopt a proper cyber-hygiene strategy regarding passwords (e.g. selecting strong ones difficult to guess) and managing them securely (e.g. using a password manager)",
      "DIGCOMP 172 : Knows how to install and activate protection software and services (e.g. antivirus, anti-malware, firewall) to keep digital content and personal data safer",
      "DIGCOMP 173 : Knows how to activate two-factor authentication when available (e.g. using one-time passwords, OTP, or codes along with access credentials)",
      "DIGCOMP 174 : Knows how to check the type of personal data an app accesses on one's mobile phone and, based on that, decides whether to install it and configures the appropriate settings",
      "DIGCOMP 175 : Able to encrypt sensitive data stored on a personal device or in a cloud storage service",
      "DIGCOMP 176 : Can respond appropriately to a security breach (i.e. an incident that results in unauthorised access to digital data, applications, networks or devices, the leaking of personal data such as logins or passwords)"
    ],
    "Attitude": [
      "DIGCOMP 177 : Vigilant not to leave computers or mobile devices unattended in public places (e.g. shared workplaces, restaurants, trains, car backseat)",
      "DIGCOMP 178 : Weighs the benefits and risks of using biometric identification techniques (e.g. fingerprint, face images) as they can affect safety in unintended ways. If biometric information is leaked or hacked, it becomes compromised and can lead to identity fraud",
      "DIGCOMP 179 : Keen to consider some self-protective behaviours such as not using open Wi-fi networks to make financial transactions or online banking",
    ]
  },
  "PROTECTING PERSONAL DATA AND PRIVACY": {
    "Knowledge": [
      "DIGCOMP 180 : Aware that secure electronic identification is a key feature designed to enable safer sharing of personal data with third parties when conducting public sector and private transactions",
      "DIGCOMP 181 : Knows that the “privacy policy” of an app or service should explain what personal data it collects (e.g. name, brand of device, geolocation of the user), and whether data are shared with third parties",
      "DIGCOMP 182 : Knows that the processing of personal data is subject to local regulations such as the EU's General Data Protection Regulation (GDPR) (e.g. voice interactions with a virtual assistant are personal data in terms of the GDPR and can expose users to certain data protection, privacy and security risks) (AI)",
    ],
    "Skill": [
      "DIGCOMP 183 : Knows how to identify suspicious e-mail messages that try to obtain sensitive information (e.g. personal data, banking identification) or might contain malware. Knows that these emails are often designed to trick people who do not check carefully and who are thus more susceptible to fraud, by containing deliberate errors that prevent vigilant people clicking on them",
      "DIGCOMP 184 : Knows how to apply basic security measures in online payments (e.g. never sending a scan of credit cards or giving the pin code of a debit/payment/credit card)",
      "DIGCOMP 185 : Knows how to use electronic identification for services provided by public authorities or public services (e.g. filling-in your tax form, applying for social benefits, requesting certificates) and by the business sector, such as banks and transport services",
      "DIGCOMP 186 : Knows how to use digital certificates acquired from certifying authorities (e.g. digital certificates for authentication and digital signing stored on national identity cards)",
    ],
    "Attitude": [
      "DIGCOMP 187 : Weighs the benefits and risks before allowing third parties to process personal data (e.g. recognises that a voice assistant on a smartphone, that is used to give commands to a robot vacuum cleaner, could give third parties - companies, governments, cybercriminals - access to the data) (AI)",
      "DIGCOMP 188 : Confident in carrying out online transactions after taking appropriate safety and security measures",
    ]
  },
  "PROTECTING HEALTH AND WELL-BEING": {
    "Knowledge": [
      "DIGCOMP 189 : Aware of the importance of balancing the use of digital technologies with non-use as an option, as many different factors in digital life can impact on personal health, wellbeing and life satisfaction",
      "DIGCOMP 190 : Knows signs of digital addictions (e.g. loss of control, withdrawal symptoms, dysfunctional mood regulation) and that digital addiction can cause psychological and physical harm",
      "DIGCOMP 191 : Aware that for many digital health applications, there are no official licensing procedures as is the case in mainstream medicine",
      "DIGCOMP 192 : Aware that some applications on digital devices (e.g. smartphones) can support the adoption of healthy behaviours by monitoring and alerting the user about health conditions (e.g. physical, emotional, psychological). However, some actions or images proposed by such applications could also have negative impacts on physical or mental health (e.g. viewing 'idealised' body images can cause anxiety)",
      "DIGCOMP 193 : Understands that cyberbullying is bullying with the use of digital technologies (i.e. a repeated behaviour aimed at scaring, angering or shaming those who are targeted)",
      "DIGCOMP 194 : Knows that the “online disinhibition effect” is the lack of restraint one feels when communicating online in comparison to communicating in-person. This can lead to an increased tendency towards online flaming (e.g. offensive language, posting insults online) and inappropriate behaviours",
      "DIGCOMP 195 : Aware that vulnerable groups (e.g. children, those with lower social skills and lack of in-person social support) are at a higher risk of victimisation in digital environments (e.g. cyber bullying, grooming)",
      "DIGCOMP 196 : Aware that digital tools can create new opportunities for participation in society for vulnerable groups (e.g. older people, people with special needs). However, digital tools can also contribute to isolation or the exclusion of those who do not use them",
    ],
    "Skill": [
      "DIGCOMP 197 : Knows how to apply, for oneself and others, a variety of digital usage monitoring and limitation strategies (e.g. rules and agreements on screen-free times, delayed availability of devices for children, installing time limitation and filter software)",
      "DIGCOMP 198 : Knows how to recognise embedded user experience techniques (e.g. clickbait, gamification, nudging) designed to manipulate and/or to weaken one's ability to be in control of decisions (e.g. make users to spend more time on online activities, encourage consumerism)",
      "DIGCOMP 199 : Can apply and follow protection strategies to fight online victimisation (e.g. block receiving further messages from sender(s), not reacting/responding, forwarding or saving messages as evidence for legal procedures, deleting negative messages to avoid repeated viewing)",

    ],
    "Attitude": [
      "DIGCOMP 200 : Inclined to focus on physical and mental wellbeing, and avoid the negative impacts of digital media (e.g. overuse, addiction, compulsive behaviour)",
      "DIGCOMP 201 : Assumes responsibility for protecting personal and collective health and safety when evaluating the effects of medical and medical-like products and services online, as the internet is awash with false and potentially dangerous information about health",
      "DIGCOMP 202 : Wary of the reliability of recommendations (e.g. are they by a reputable source) and their intentions (e.g. do they really help the user vs encourage to use the device more to be exposed to advertising)",
    ]
  },
  "PROTECTING THE ENVIRONMENT": {
    "Knowledge": [
      "DIGCOMP 203 : Aware of the environmental impact of everyday digital practices (e.g. video streaming that rely on data transfer), and that the impact is composed of energy use and carbon emissions from devices, network infrastructure and data centres",
      "DIGCOMP 204 : Aware of the environmental impact of the manufacturing of digital devices and batteries (e.g. pollution and toxic by-products, consumption of energy) and that at the end of their life, such devices must be appropriately disposed of to minimise their environmental impact and to enable reuse of rare and expensive components and natural resources",
      "DIGCOMP 205 : Aware that some components of electronic and digital devices can be replaced to extend their life or performance, however, some might be purposefully designed to stop functioning correctly after a certain period (planned obsolescence)",
      "DIGCOMP 206 : Knows 'green' behaviours to follow when buying digital devices, e.g. choose products with less energy consumption during use and stand-by, less polluting (products easier to dismantle and recycle) and less toxic (limited use of substances harmful to the environment and health)",
      "DIGCOMP 207 : Knows that e-commerce practices such as purchasing and delivery of physical goods have an impact on the environment (e.g. carbon footprint of transport, generation of waste)",
      "DIGCOMP 208 : Aware that digital technologies (including AI-driven ones) can contribute to energy efficiency, for example through monitoring the need for heating at home and optimising its management",
      "DIGCOMP 209 : Aware that certain activities (e.g. training AI and producing cryptocurrencies like Bitcoin) are resource intensive processes in terms of data and computing power. Therefore, energy consumption can be high which can also have a high environmental impact (AI)",
    ],
    "Skill": [
      "DIGCOMP 210 : Knows how to apply efficient low-tech strategies for protecting the environment, e.g. shutting down devices and switching off Wi-fi, not printing out documents, repair and replace component to avoid the unnecessary replacement of digital devices",
      "DIGCOMP 211 : Knows how to reduce the energy consumption of devices and services used, e.g. change the quality settings of video streaming services, using Wi-fi rather than data connectivity when at home, closing apps, optimising email attachments)",
      "DIGCOMP 212 : Knows how to use digital tools to improve the environmental and social impact of one's consumer behaviour (e.g. by looking for local produce, by searching for collective deals and car-pooling options for transportation)",

    ],
    "Attitude": [
      "DIGCOMP 213 : Seeks out ways in which digital technologies could help live and consume in a way which respects the sustainability of human society and the natural environment",
      "DIGCOMP 214 : Seeks out information regarding the environmental impact of technology to influence one's behaviour and that of others (e.g. friends and family) to be more eco-responsible in their digital practices",
      "DIGCOMP 215 : Considers product's overall impact on the planet when choosing digital means over physical products, e.g. reading a book online does not need paper and thus transport costs are low, however, one should consider digital devices including toxic component and needed energy to be charged",
      "DIGCOMP 216 : Considers the ethical consequences of AI systems throughout their life-cycle: they include both the environmental impact (environmental consequences of the production of digital devices and services) and societal impact, e.g. platformisation of work and algorithmic management that may repress workers' privacy or rights; the use of low-cost labour for labelling images to train AI systems (AI)",
    ]
  },
  "SOLVING TECHNICAL PROBLEMS": {
    "Knowledge": [
      "DIGCOMP 217 : Knows the main functions of the most common digital devices (e.g. computer, tablet, smartphone)",
      "DIGCOMP 218 : Knows some reasons why a digital device may fail to connect online (e.g. wrong Wi-fi password, airplane mode on)",
      "DIGCOMP 219 : Knows that computing power or storage capacity can be improved to overcome fast obsolescence of hardware (e.g. by contracting power or storage as a service)",
      "DIGCOMP 220 : Aware that the most frequent sources of problems in Internet of Thing (IoT) and mobile devices, and in their applications, are related to connectivity/network availability, battery/power, limited processing power",
      "DIGCOMP 221 : Aware that AI is a product of human intelligence and decision-making (i.e. humans choose, clean and encode the data, they design the algorithms, train the models, and curate and apply human values to the outputs) and therefore does not exist independently of humans",
    ],
    "Skill": [
      "DIGCOMP 222 : Knows how to identify and solve a camera and/or a microphone issue when in an online meeting",
      "DIGCOMP 223 : Knows how to verify and troubleshoot problems related to interconnected IoT devices and their services",
      "DIGCOMP 224 : Takes a step-by-step approach to identify the root of a technical problem (e.g. hardware vs software) and explores various solutions when facing a technical malfunction",
      "DIGCOMP 225 : Knows how to find solutions on the internet when facing a technical problem",

    ],
    "Attitude": [
      "DIGCOMP 226 : Takes an active and curiosity driven approach to explore how digital technologies operate",
    ]
  },
  "IDENTIFYING NEEDS AND TECHNOLOGICAL RESPONSES": {
    "Knowledge": [
      "DIGCOMP 227 : Knows that it is possible to buy and sell goods and services on the internet through commercial transactions (e.g. e-commerce) and consumer-to-consumer transactions (e.g. sharing platforms). Different rules (e.g legal consumer protections) apply when buying online from a company than from a private person",
      "DIGCOMP 228 : Able to identify some examples of AI systems: product recommenders (e.g. on online shopping sites), voice recognition (e.g. by virtual assistants), image recognition (e.g. for detecting tumours in x-rays) and facial recognition (e.g. in surveillance systems) (AI)",
      "DIGCOMP 229 : Aware that many non-digital artefacts can be created using 3D printer (e.g. to print spare parts for domestic appliances or furniture",
      "DIGCOMP 230 : Knows technical approaches that can improve the inclusiveness and accessibility of digital content and services, e.g. tools such as magnification or zoom and text-to-voice functionality (DA)",
      "DIGCOMP 231 : Aware that AI-driven speech-based technology enables the use of spoken commands that can enhance the accessibility of digital tools and devices (e.g. for those with mobility or visual limitations, limited cognition, language or learning difficulties), however, languages spoken by smaller populations are often not available, or perform worse, due to commercial prioritisation (AI) (DA)",
    ],
    "Skill": [
      "DIGCOMP 232 : Knows how to use the internet to conduct transactions (e.g. purchasing, selling) and non-commercial ones (e.g. donating, gifting) of goods and services of all kinds",
      "DIGCOMP 233 : Knows how and when to use machine translation solutions (e.g. Google Translate, DeepL) and simultaneous interpretation apps (e.g. iTranslate) to get a rough understanding of a document or conversation. However, also knows that when the content requires an accurate translation (e.g. in healthcare, commerce or diplomacy), a more precise translation may be needed (AI)",
      "DIGCOMP 234 : Knows how to choose assistive tools to better access information and content online (e.g. screen readers, voice recognition tools), and to take advantage of voice output options to produce speech (e.g. to be used by individuals who have limited or no means to communicate orally) (DA)",
    ],
    "Attitude": [
      "DIGCOMP 235 : Values the benefits of managing finances and financial transactions through digital means while acknowledging the associated risks",
      "DIGCOMP 236 : Open to explore and spot opportunities created by digital technologies for one's personal needs (e.g. seeking hearing aids that pair with one's most-used devices, such as phone, TV, camera, smoke-alarm). Critically aware that exclusive reliance on digital technologies can pose risks too",
    ]
  },
  "CREATIVELY USING DIGITAL TECHNOLOGY": {
    "Knowledge": [
      "DIGCOMP 237 : Knows that engaging in solving problems collaboratively, online or off-screen, means that one can take advantage of the variety of knowledge, perspectives and experiences from others which can lead to better outcomes",
      "DIGCOMP 238 : Knows that digital technologies and electronic devices can be used as a tool to support the innovation of new processes and products, in order to create social, cultural and/or economic value (e.g. social innovation). Aware that what creates economic value might endanger or enhance social or cultural value",
      "DIGCOMP 239 : Knows that applications of Internet of Things (IoT) technology have the potential to be used in many different sectors (e.g. healthcare, agriculture, industry, automobiles, citizen science activities)",
    ],
    "Skill": [
      "DIGCOMP 240 : Knows how to use digital technologies to help turn one's idea into action (e.g. master video making to open a channel to share recipes and nutrition tips for a specific dietary style)",
      "DIGCOMP 241 : Can identify online platforms that can be used to design, develop and test IoT technologies and mobile apps",
      "DIGCOMP 242 : Knows how to plan a strategy using multiple IoT and mobile devices to implement a task (e.g. use a smartphone to optimise energy consumption in a room by setting the intensity of the lights based on the time of day and ambient light)",
      "DIGCOMP 243 : Knows how to engage in resolving social problems through digital, hybrid and non-digital solutions for the problem (e.g. envisioning and planning online time banks, public reporting systems, resource sharing platforms)",
    ],
    "Attitude": [
      "DIGCOMP 244 : Willing to take part in challenges and contests aimed at solving intellectual, social or practical problems through digital technologies (e.g. hackathons, ideations, grants, joint initiation of projects)",
      "DIGCOMP 245 : Motivated to co-design and co-create new products and services using digital devices (i.e. end-user development) to create economic or social value for others (e.g. in makerspaces and other collective spaces)",
      "DIGCOMP 246 : Open to engage in collaborative processes to co-design and co-create new products and services based on AI systems to support and enhance citizens' participation in society (AI)",
    ]
  },
  "IDENTIFYING DIGITAL COMPETENCE GAPS": {
    "Knowledge": [
      "DIGCOMP 247 : Aware that being digitally competent entails the confident, critical and responsible use of digital technologies to achieve goals related to work, learning, leisure, inclusion and participation in society",
      "DIGCOMP 248 : Aware that difficulties experienced while interacting with digital technologies may be due to technical issues, lack of confidence, one's own competence gap or inadequate choice of digital tool to solve the problem in question",
      "DIGCOMP 249 : Aware that digital tools can be used to help identify one's learning interests and setting personal goals in life (e.g. learning pathways)",
      "DIGCOMP 250 : Knows that online learning can offer opportunities (e.g. video-tutorials, online-seminars, blended-learning-courses, Massive Open Online Courses) to keep up-to-date with developments in digital technologies and to develop new digital skills. Some online learning opportunities also accredit the learning outcomes (e.g. through micro-credentials, certifications)",
      "DIGCOMP 251 : Aware that AI is a constantly-evolving field, whose development and impact is still very unclear (AI)",
    ],
    "Skill": [
      "DIGCOMP 252 : Knows how to get reliable feedback on digital competence through self-assessment tools, digital skills testing and certification",
      "DIGCOMP 253 : Capable of reflecting on one's level of competence, and to make plans and take action to upskill (e.g. by joining the municipality training course on digital competence)",
      "DIGCOMP 254 : Knows how to talk about the importance of recognising “fake news” to others (e.g. elders, youngsters) by showing examples of reliable news sources, and how to differentiate between the two",
    ],
    "Attitude": [
      "DIGCOMP 255 : Has a disposition to keep learning, to educate oneself and stay informed about AI (e.g. to understand how AI algorithms work; to understand how automatic decision-making can be biased; to distinguish between realistic and unrealistic AI; and to understand the difference between Artificial Narrow Intelligence, i.e. today's AI capable of narrow tasks such as game playing, and Artificial General Intelligence, i.e. AI that surpasses human intelligence, which still remains science fiction) (AI)",
      "DIGCOMP 256 : Open to ask to be taught how to use an application (e.g. how to book a doctor's appointment on the internet) rather than delegating the task to someone else",
      "DIGCOMP 257 : Willing to help others to improve their digital competencies, building on their strengths and mitigating their weaknesses",
      "DIGCOMP 258 : Does not get discouraged by the fast pace of technological changes but believes that one can always learn more about how technology can be used in today's society",
      "DIGCOMP 259 : Readiness to value one's own potential, as well as others' potential, to continuously learn using digital technologies as a lifelong process that requires openness, curiosity and determination",
    ]
  },
}

export const PERENCompetences = {
  "Medias": ["S'initier à un regard sélectif et critique face aux médias", "Développer son esprit critique face aux médias", "Analyser et évaluer des contenus médiatiques"],
  "Science informatique": ["Découvrir la science informatique", "S'approprier les concepts de base de la science informatique", "Poser et résoudre des problèmes en science informatique"],
  "Usages": ["Découvrir et utiliser des outils numériques", "Utiliser des outils numériques pour réaliser des projets", "Exploiter des outils numériques pour collecter l'information, pour échanger et pour réaliser des projets"]
};

export const PERENActivities = {
  "S'initier à un regard sélectif et critique face aux médias": [
    "PER EN 11.1 : Échange sur les expériences liées à l'utilisation des médias",
    "PER EN 11.2 : Sensibilisation aux possibilités offertes (accès au savoir, à l'information, divertissement, …) par l'utilisation des médias",
    "PER EN 11.3 : Sensibilisation au rapport entre l'image et la réalité",
    "PER EN 11.4 : Découverte des spécificités des différents supports médiatiques (internet, presse papier, télévision, radio, cinéma, …)",
    "PER EN 11.5 : Découverte des éléments de lecture et de compréhension d'une image (cadrage, couleurs, lumière, …)",
    "PER EN 11.6 : Repérage des différents éléments (texte, image fixe, image en mouvement, animation interactive, son, …) entrant dans la composition d'un message médiatique (page web, publicité, affiche, …)",
    "PER EN 11.7 : Découverte du rapport entre l'image et le son",
    "PER EN 11.8 : Sensibilisation à l'acte de communication à l'aide d'un média",
    "PER EN 11.9 : Sensibilisation aux intentions d'un message en tenant compte du contexte de communication",
    "PER EN 11.10 : Confrontation des différentes réactions face à un message issu des médias et mise en évidence de ses propres critères de préférence",
    "PER EN 11.11 : Réalisation de créations médiatiques variées",
    "PER EN 11.12 : Contribution à la diffusion de créations de la classe(exposition, site de classe, …)"],
  "Développer son esprit critique face aux médias": [
    "PER EN 21.1 : Mise en évidence des stéréotypes (genres, origines, âges, …) véhiculés au travers de différents médias (analyse d'une publicité, d'un dessin animé, …)",
    "PER EN 21.2 : Sensibilisation à la notion de fiabilité de l'information",
    "PER EN 21.3 : Découverte de quelques critères d'évaluation de la fiabilité de l'information (source, auteur, date, intention, …)",
    "PER EN 21.4 : Identification de quelques étapes significatives du développement des médias dans l'histoire",
    "PER EN 21.5 : Comparaison et analyse des spécificités de différents supports médiatiques (par l'observation d'articles de journal, de films, de pages web, d'émissions de radio, ...)",
    "PER EN 21.6 : Mise en évidence des différences dans le traitement de l'information selon le média (entre médias du même type ou entre médias de types différents) et interrogation sur sa pertinence",
    "PER EN 21.7 : Repérage des différents éléments qui composent un message médiatique (texte, typographie, hypertexte, image fixe, image en mouvement, animation interactive, son, …)",
    "PER EN 21.8 : Exploration des principaux éléments d'analyse d'une image fixe ou en mouvement (cadrage, couleur, lumière, profondeur de champ, rythme, mouvement, champ/hors-champ, plans, mise en scène, …) et du rapport entre l'image et le son",
    "PER EN 21.9 : Identification des intentions et du contexte d'un message médiatique",
    "PER EN 21.10 : Sensibilisation aux fonctions des médias (information, éducation, formation de l'opinion, divertissement, communication, …)",
    "PER EN 21.11 : Sélection d'un média, en fonction de ses spécificités, en vue d'une création collective et de sa publication"],
  "Analyser et évaluer des contenus médiatiques": [
    "PER EN 31.1 : Identification de différents types de messages véhiculés par les médias (informatif, publicitaire, individuel, institutionnel, …) et évaluation critique de la source",
    "PER EN 31.2 : Décodage des intentions latentes d'un message (commerciales, politiques, …)",
    "PER EN 31.3 : Sensibilisation à l'influence sur la société des politiques et des stratégies des grandes compagnies qui développent les outils numériques utilisés au quotidien",
    "PER EN 31.4 : Réflexion sur la pénétration croissante des médias dans la vie quotidienne et sur leurs impacts social, économique et environnemental",
    "PER EN 31.5 : Sensibilisation à l'influence des médias sur la culture, l'économie et la politique (accès au savoir, manipulation de l'information, dépendance technique, financement des offres médiatiques, médias comme quatrième pouvoir, …) à travers le temps",
    "PER EN 31.6 : Analyse de tout acte de communication au moyen des questions de base : qui ? à qui ? quoi ? pourquoi ? comment ? où ? quand ?",
    "PER EN 31.7 : Étude de créations médiatiques à l'aide d'outils d'analyse du message et du support (stéréotype, portée sociale du message, grammaire de l'image et du son, aspect subliminal, points forts et limites du support, …)",
    "PER EN 31.8 : Reconnaissance des différences dans le traitement de l'information selon le média (entre médias du même type ou entre médias de types différents) et analyse de leur pertinence (choc des photos, choix du titre, distinction entre une information brute et son commentaire, …)",
    "PER EN 31.9 : Analyse de différents éléments entrant dans la composition d'un message médiatique (texte, hypertexte, image fixe, image en mouvement, animation interactive, son, …)",
    "PER EN 31.10 : Analyse d'éléments inhérents à la composition d'une image fixe ou en mouvement (cadrage, couleur, lumière, profondeur de champ, rythme, mouvement, champ/hors champ, plans, mise en scène, …) et du rapport entre l'image et le son",
    "PER EN 31.11 : Analyse du rapport entre l'image et la réalité",
    "PER EN 31.12 : Choix d'un média approprié en fonction d'un projet en vue de sa réalisation et de sa publication",
    "PER EN 31.13 : Prise en compte des notions liées au droit d'auteur et à l'image. Citation des sources"],
  "Découvrir la science informatique": [
    "PER EN 12.1 : Réflexion sur la place de l'informatique dans la classe et au quotidien",
    "PER EN 12.2 : Réalisation d'activités en lien avec la vie courante comprenant une suite d'instructions pour découvrir la notion d'algorithme 1",
    "PER EN 12.3 : Réalisation d'activités en lien avec la vie courante comprenant une suite d'instructions pour découvrir la notion d'algorithme 2",
    "PER EN 12.4 : Déplacement d'un objet ou d'une personne selon des instructions précises et préalablement négociées",
    "PER EN 12.5 : Déplacement d'un objet ou d'une personne selon des instructions incluant des boucles et des conditions",
    "PER EN 12.6 : Commande d'un automate ou d'un robot",
    "PER EN 12.7 : Commande et programmation d'un automate, d'un robot ou d'un personnage virtuel",
    "PER EN 12.8 : Utilisation de symboles pour représenter une information",
    "PER EN 12.9 : Classement d'objets en fonction d'une ou plusieurs caractéristiques et dénombrement 1",
    "PER EN 12.10 : Classement d'objets en fonction d'une ou plusieurs caractéristiques et dénombrement 2",
    "PER EN 12.11 : Utilisation d'une terminologie appropriée pour identifier, nommer et décrire les principaux composants visibles : clavier, écran, capteur, ...",
    "PER EN 12.12 : Découverte par l'expérimentation des différences entre l'humain, le robot et l'automate",
    "PER EN 12.13 : Sensibilisation au fait que les objets sont reliés entre eux et communiquent les uns avec les autres"],
  "S'approprier les concepts de base de la science informatique": [
    "PER EN 22.1 : Réflexion sur la place de l'informatique dans la société",
    "PER EN 22.2 : Sensibilisation à la place prépondérante des moteurs de recherche dans la recherche d'information",
    "PER EN 22.3 : Découverte des différences résultant de l'utilisation de moteurs de recherche variés",
    "PER EN 22.4 : Sensibilisation aux enjeux de la collecte et l'exploitation des données personnelles et des traces numériques",
    "PER EN 22.5 : Création et comparaison de programmes avec des séquences, des tests conditionnels et des boucles à l'aide d'un langage de programmation visuel pour résoudre des problèmes simples",
    "PER EN 22.6 : Découverte et création de sous-programmes pour améliorer un programme",
    "PER EN 22.7 : Utilisation de paramètres pour modifier un programme",
    "PER EN 22.8 : Utilisation d'un système binaire pour représenter une image matricielle",
    "PER EN 22.9 : Passage d'un nombre naturel à son équivalent binaire à l'aide d'une table de conversion",
    "PER EN 22.10 : Cryptage et décryptage d'un message à l'aide de méthodes simples",
    "PER EN 22.11 : Découverte des différents types de fichiers permettant de représenter des informations",
    "PER EN 22.12 : Découverte des unités de mesure (octet, Ko, Mo, Go) de données informatiques et initiation à la notion de taille d'un fichier en fonction de son type et de son contenu",
    "PER EN 22.13 : Découverte des principes de la compression avec ou sans perte de qualité",
    "PER EN 22.14 : Initiation au fonctionnement global d'un moteur de recherche",
    "PER EN 22.15 : Identification des composants principaux (processeur, mémoire, dispositifs d'entrée/sortie, …) de différents types de machines (ordinateur, tablette, robot,…) et de leurs fonctions",
    "PER EN 22.16 : Différenciation entre un système d'exploitation, un logiciel et un document ou site web",
    "PER EN 22.17 : Découverte du fonctionnement de base d'un réseau informatique",
    "PER EN 22.18 : Découverte de techniques simples de sécurité de systèmes informatiques"],
  "Poser et résoudre des problèmes en science informatique": [
    "PER EN 32.1 : Découverte des grandes étapes de l'histoire de l'informatique",
    "PER EN 32.2 : Sensibilisation à l'évolution permanente du numérique en identifiant les impacts social, économique et environnemental",
    "PER EN 32.3 : Analyse des processus derrière la collecte et l'exploitation des données personnelles et réflexion sur leurs enjeux politiques, économiques et médiatiques",
    "PER EN 32.4 : Sensibilisation au lien entre langage de programmation visuel et textuel",
    "PER EN 32.5 : Création de courts programmes avec des séquences, tests, boucles et variables à l'aide d'un langage de programmation visuel et/ou textuel pour résoudre un problème",
    "PER EN 32.6 : Comparaison de plusieurs algorithmes permettant de résoudre un même problème et évaluation de la solution",
    "PER EN 32.7 : Découverte de simulation de phénomènes naturels ou sociaux simples",
    "PER EN 32.8 : Utilisation d'un codage informatique pour représenter différents types de données",
    "PER EN 32.9 : Identification des métadonnées incluses dans divers types de fichiers (date, auteur, localisation, …)",
    "PER EN 32.10 : Découverte de quelques principes de cryptage pour l'échange sécurisé de données",
    "PER EN 32.11 : Découverte de divers modes de compression pour un type de données",
    "PER EN 32.12 : Découverte des principes de la détection (voire correction) d'erreurs par la machine",
    "PER EN 32.13 : Découverte de la différence entre la réplication et la synchronisation des données",
    "PER EN 32.14 : Découverte des principes de la transmission d'informations sur un réseau informatique via des protocoles de communication",
    "PER EN 32.15 : Comparaison de différents modes de stockage (localement ou à distance, privé ou public) et des vitesses de transfert de données",
    "PER EN 32.16 : Découverte de la diversité d'objets informatiques (systèmes embarqués, objets connectés, …) et de leurs particularités",
    "PER EN 32.17 : Découverte des parades contre différents types d'attaques sur des systèmes informatiques"],
  "Découvrir et utiliser des outils numériques": [
    "PER EN 13.1 : Sensibilisation quant au temps passé devant les écrans",
    "PER EN 13.2 : Sensibilisation au droit à l'image",
    "PER EN 13.3 : Initiation aux règles de sécurité sur les identifiants et mots de passe",
    "PER EN 13.4 : Identification de comportements à adopter face à des contenus choquants",
    "PER EN 13.5 : Initiation à l'utilisation d'appareils numériques (ordinateur, tablette, robot, ...)",
    "PER EN 13.6 : Découverte de logiciels de création (dessin, photo, musique, …)",
    "PER EN 13.7 : Découverte et utilisation de logiciels de création (dessin, texte, musique, vidéo, ....)",
    "PER EN 13.8 : Découverte des plateformes pédagogiques mises à disposition de l'école",
    "PER EN 13.9 : Utilisation des plateformes pédagogiques mises à disposition de l'école",
    "PER EN 13.10 : Découverte de dispositifs de saisie (clavier, souris, pavé tactile, écran tactile, stylet, reconnaissance vocale, …)",
    "PER EN 13.11 : Utilisation du clavier et positionnement du curseur dans le texte",
    "PER EN 13.12 : Découverte de diverses ressources numériques d'apprentissage (logiciel, application, ...)",
    "PER EN 13.13 : Découverte de diverses ressources numériques d'apprentissage (logiciel, application, ...) sans ou avec processus d'identification de classe",
    "PER EN 13.14 : Consultation de ressources numériques de natures différentes présélectionnées par l'enseignant",
    "PER EN 13.15 : Navigation à partir d'un portail destiné aux enfants dans le cadre d'un projet défini",
    "PER EN 13.16 : Initiation à la présentation d'un projet de classe au format numérique",
    "PER EN 13.17 : Sensibilisation à la communication numérique (envoi de dessins, de sons, de messages, …)"],
  "Utiliser des outils numériques pour réaliser des projets": [
    "PER EN 23.1 : Comparaison des lieux et horaires de consommation des écrans",
    "PER EN 23.2 : Analyse de sa consommation et de ses usages des médias",
    "PER EN 23.3 : Comparaison de différents types d'interactions sociales (numériques vs physiques)",
    "PER EN 23.4 : Application des règles de sécurité sur ses identifiants, ses mots de passe et ses données personnelles et respect de ceux de ses pairs",
    "PER EN 23.5 : Découverte de la notion d'identité et d'empreinte numériques",
    "PER EN 23.6 : Sensibilisation à la consommation énergétique liée à l'utilisation du numérique (mise en route, mise en veille, communication, jeu, création, transmission, stockage des données, …)",
    "PER EN 23.7 : Sensibilisation aux phénomènes d'amplification du harcèlement par le numérique et mise en évidence des comportements adéquats pour y réagir en tant que témoin ('spect'acteur'), cible ou auteur",
    "PER EN 23.8 : Présentation des notions liées au droit d'auteur et à l'image",
    "PER EN 23.9 : Utilisation de divers appareils numériques (ordinateur, tablette, robot, ...) adaptés à la tâche projetée",
    "PER EN 23.10 : Découverte et utilisation de logiciels adaptés à la tâche projetée",
    "PER EN 23.11 : Sensibilisation au choix du nom d'un fichier et à son emplacement de sauvegarde",
    "PER EN 23.12 : Gestion de ses fichiers dans un environnement prédéfini",
    "PER EN 23.13 : Saisie d'un texte en favorisant l'utilisation d'un dispositif adapté (clavier, écran tactile, stylet, reconnaissance vocale, …) et en respectant les conventions de lisibilité (espaces, mises en forme, …)",
    "PER EN 23.14 : Exploration de possibilités et de limites des aides à l'écriture (orthographe, synonymes, grammaire, auto-complétion, …)",
    "PER EN 23.15 : Exploration de quelques possibilités d'aide à l'utilisation des logiciels",
    "PER EN 23.16 : Utilisation autonome de diverses ressources numériques d'apprentissage sans ou avec processus d'identification personnelle",
    "PER EN 23.17 : Navigation et recherche dans le cadre d'un projet défini à partir de moteurs de recherche pour enfant",
    "PER EN 23.18 : Navigation et recherche dans le cadre d'un projet défini à partir de moteurs de recherche",
    "PER EN 23.19 : Réalisation numérique dans le cadre d'un projet individuel ou collectif, et publication",
    "PER EN 23.20 : Initiation à la communication numérique (au moyen de la messagerie, de la visioconférence, …) avec le concours d'un adulte"],
  "Exploiter des outils numériques pour collecter l'information, pour échanger et pour réaliser des projets": [
    "PER EN 33.1 : Analyse de certains usages du numérique, opportuns ou à risques, et de leur impact sur l'identité numérique",
    "PER EN 33.2 : Application des règles de protection face aux phénomènes d'amplification du harcèlement par le numérique et réaction de manière adéquate en tant que témoin ('spect'acteur'), cible ou auteur",
    "PER EN 33.3 : Sensibilisation à l'évolution permanente du numérique en identifiant les impacts environnemental et économique (obsolescence programmée, recyclage, …)",
    "PER EN 33.4 : Choix et utilisation d'appareils numériques (ordinateur, tablette, robot, ...) adaptés à la tâche",
    "PER EN 33.5 : Découverte et utilisation de logiciels adaptés à la tâche projetée (texte, représentation graphique, présentation animée, dessin, son, vidéo, page web, programme, …)",
    "PER EN 33.6 : Découverte des principales fonctionnalités d'une plateforme collaborative",
    "PER EN 33.7 : Saisie d'un texte en utilisant les fonctionnalités d'aide à l'écriture (correcteur orthographique et grammatical, auto-complétion, modèles, …) et en favorisant le dispositif de saisie adapté à la tâche (clavier, écran tactile, stylet, reconnaissance vocale, …)",
    "PER EN 33.8 : Gestion autonome du stockage et du partage de fichiers",
    "PER EN 33.9 : Recours autonome à des aides en ligne pour l'utilisation de logiciels",
    "PER EN 33.10 : Gestion de comptes personnels avec identifiant et mot de passe sécurisé",
    "PER EN 33.11 : Utilisation d'un compte de messagerie individuel (envoi, réception, recherche, gestion des contacts, classement, suppression, …)",
    "PER EN 33.12 : Choix et utilisation autonome de diverses ressources numériques d'apprentissage",
    "PER EN 33.13 : Navigation et recherche de manière autonome, en utilisant les différentes techniques (syntaxe) proposées par les moteurs de recherche",
    "PER EN 33.14 : Communication et échange au moyen de plateformes collaboratives au service de la gestion et du suivi de son travail",
    "PER EN 33.15 : Partage des tâches, communication et collaboration dans le cadre d'un projet collectif"]
}

export const studentProficiencyLevels = {
  "DIGCOMP": [
    "Foundation (1)",
    "Foundation (2)",
    "Intermediate (3)",
    "Intermediate (4)",
    "Advanced (5)",
    "Advanced (6)",
    "Highly specialized (7)",
    "Highly specialized (8)"
  ],
  "PER EN": [
    "Niveau 1",
    "Niveau 2",
    "Niveau 3"
  ]
};

export const lehrplanMIElementaryCompetences = {
  "Medien": ["MI.1.1", "MI.1.2", "MI.1.3", "MI.1.4"],
  "Informatik": ["MI.2.1", "MI.2.2", "MI.2.3"]
};

export const lehrplanMIActivities = {
  "MI.1.1": [
    "LEHRPLAN MI 1.1.a : können sich über Erfahrungen in ihrer unmittelbaren Umwelt, über Medienerfahrungen sowie Erfahrungen in virtuellen Lebensräumen austauschen und über ihre Mediennutzung sprechen (z.B. Naturerlebnis, Spielplatz, Film, Fernsehen, Bilderbuch, Hörspiel, Lernprogramm)",
    "LEHRPLAN MI 1.1.b : können Vor- und Nachteile direkter Erfahrungen, durch Medien oder virtuell vermittelter Erfahrungen benennen und die persönliche Mediennutzung begründen",
    "LEHRPLAN MI 1.1.c : können Folgen medialer und virtueller Handlungen erkennen und benennen (z.B. Identitätsbildung, Beziehungspflege, Cybermobbing)",
    "LEHRPLAN MI 1.1.d : können Regeln und Wertesysteme verschiedener Lebenswelten unterscheiden, reflektieren und entsprechend handeln (z.B. Netiquette, Werte in virtuellen Welten)",
    "LEHRPLAN MI 1.1.e.1 : können Chancen und Risiken der Mediennutzung benennen und Konsequenzen für das eigene Verhalten ziehen (z.B. Vernetzung, Kommunikation, Cybermobbing, Schuldenfalle, Suchtpotential)",
    "LEHRPLAN MI 1.1.e.2 : können Verflechtungen und Wechselwirkungen zwischen physischer Umwelt, medialen und virtuellen Lebensräumen erkennen und für das eigene Verhalten einbeziehen (z.B. soziale Netzwerke und ihre Konsequenzen im realen Leben)",
    "LEHRPLAN MI 1.1.f : können Chancen und Risiken der zunehmenden Durchdringung des Alltags durch Medien und Informatik beschreiben (z.B. Globalisierung, Automatisierung, veränderte Berufswelt, ungleiche Möglichkeiten zum Zugang zu Information und Technologie)",
    "LEHRPLAN MI 1.1.g : können Funktion und Bedeutung der Medien für Kultur, Wirtschaft und Politik beschreiben und darlegen, wie gut einzelne Medien diese Funktion erfüllen (z.B. Manipulation, technische Abhängigkeit, Medien als vierte Gewalt)"],
  "MI.1.2": [
    "LEHRPLAN MI 1.2.a.1 : verstehen einfache Beiträge in verschiedenen Mediensprachen und können darüber sprechen (Text, Bild, alltägliches Symbol, Ton, Film)",
    "LEHRPLAN MI 1.2.a.2 : können Werbung erkennen und über die Zielsetzung der Werbebotschaften sprechen",
    "LEHRPLAN MI 1.2.b : können benennen, welche unmittelbaren Emotionen die Mediennutzung auslösen kann (z.B. Freude, Wut, Trauer)",
    "LEHRPLAN MI 1.2.c : können mithilfe von vorgegebenen Medien lernen und Informationen zu einem bestimmten Thema beschaffen (z.B. Buch, Zeitschrift, Lernspiel, Spielgeschichte, Website)",
    "LEHRPLAN MI 1.2.d.1 : können die Grundfunktionen der Medien benennen (Information, Bildung, Meinungsbildung, Unterhaltung, Kommunikation)",
    "LEHRPLAN MI 1.2.d.2 : kennen Mischformen und können typische Beispiele aufzählen (Infotainment, Edutainment)",
    "LEHRPLAN MI 1.2.e : können Informationen aus verschiedenen Quellen gezielt beschaffen, auswählen und hinsichtlich Qualität und Nutzen beurteilen",
    "LEHRPLAN MI 1.2.f : erkennen, dass Medien und Medienbeiträge auf Individuen unterschiedlich wirken",
    "LEHRPLAN MI 1.2.g : kennen grundlegende Elemente der Bild-, Film- und Fernsehsprache und können ihre Funktion und Bedeutung in einem Medienbeitrag reflektieren",
    "LEHRPLAN MI 1.2.h : können die Absicht hinter Medienbeiträgen einschätzen (z.B. Werbung, Zeitschrift, Parteizeitung)",
    "LEHRPLAN MI 1.2.i : kennen Organisations- und Finanzierungsformen von Medienangeboten und deren Konsequenzen"],
  "MI.1.3": [
    "LEHRPLAN MI 1.3.a : können spielerisch und kreativ mit Medien experimentieren",
    "LEHRPLAN MI 1.3.b : können einfache Bild-, Text-, Tondokumente gestalten und präsentieren",
    "LEHRPLAN MI 1.3.c : können Medien zum Erstellen und Präsentieren ihrer Arbeiten einsetzen (z.B. Klassenzeitung, Klassenblog, Hörspiel, Videoclip)",
    "LEHRPLAN MI 1.3.d : können in ihren Medienbeiträgen die Sicherheitsregeln im Umgang mit persönlichen Daten einbeziehen (z.B. Angaben zur Person, Passwort, Nickname)",
    "LEHRPLAN MI 1.3.e : können Medieninhalte weiterverwenden und unter Angabe der Quelle in Eigenproduktionen integrieren (z.B. Vortrag, Blog/Klassenblog)",
    "LEHRPLAN MI 1.3.f.1 : können Medien nutzen, um ihre Gedanken und ihr Wissen vor Publikum zu präsentieren und/oder zu veröffentlichen",
    "LEHRPLAN MI 1.3.f.2 : können Wirkungen eigener Medienbeiträge einschätzen und bei der Produktion entsprechend berücksichtigen",
    "LEHRPLAN MI 1.3.g : können mit eigenen und fremden Inhalten Medienbeiträge herstellen und berücksichtigen dabei die rechtlichen Rahmenbedingungen sowie Sicherheits- und Verhaltensregeln",
    "LEHRPLAN MI 1.3.h : können allein und in Arbeitsteams mit medialen Möglichkeiten experimentieren und sich darüber austauschen"],
  "MI.1.4": [
    "LEHRPLAN MI 1.4.a : können mittels Medien bestehende Kontakte pflegen und sich austauschen (z.B. Telefon, Brief)",
    "LEHRPLAN MI 1.4.b : können Medien für gemeinsames Arbeiten und für Meinungsaustausch einsetzen und dabei die Sicherheitsregeln befolgen",
    "LEHRPLAN MI 1.4.c : können mittels Medien kommunizieren und dabei die Sicherheits- und Verhaltensregeln befolgen",
    "LEHRPLAN MI 1.4.d : können Medien gezielt für kooperatives Lernen nutzen",
    "LEHRPLAN MI 1.4.e : können Medien zur Veröffentlichung eigener Ideen und Meinungen nutzen und das Zielpublikum zu Rückmeldungen motivieren",
    "LEHRPLAN MI 1.4.f : können kooperative Werkzeuge anpassen und für gemeinsames Arbeiten, Meinungsaustausch, Kommunikation sowie zum Publizieren einsetzen (z.B. Blog, Wiki)"],
  "MI.2.1": [
    "LEHRPLAN MI 2.1.a : können Dinge nach selbst gewählten Eigenschaften ordnen, damit sie ein Objekt mit einer bestimmten Eigenschaft schneller finden (z.B. Farbe, Form, Grösse)",
    "LEHRPLAN MI 2.1.b : können unterschiedliche Darstellungsformen für Daten verwenden (z.B. Symbole, Tabellen, Grafiken)",
    "LEHRPLAN MI 2.1.c : können Daten mittels selbstentwickelter Geheimschriften verschlüsseln",
    "LEHRPLAN MI 2.1.d : kennen analoge und digitale Darstellungen von Daten (Text, Zahl, Bild und Ton) und können die entsprechenden Dateitypen zuordnen",
    "LEHRPLAN MI 2.1.e : kennen die Bezeichnungen der von ihnen genutzten Dokumententypen",
    "LEHRPLAN MI 2.1.f : erkennen und verwenden Baum- und Netzstrukturen (z.B. Ordnerstruktur auf dem Computer, Stammbaum, Mindmap, Website)",
    "LEHRPLAN MI 2.1.g : verstehen die Funktionsweise von fehlererkennenden und -korrigierenden Codes",
    "LEHRPLAN MI 2.1.h : können Dokumente so ablegen, dass auch andere sie wieder finden",
    "LEHRPLAN MI 2.1.i : können logische Operatoren verwenden (und, oder, nicht)",
    "LEHRPLAN MI 2.1.j : können Daten in einer Datenbank strukturieren, erfassen, suchen und automatisiert auswerten",
    "LEHRPLAN MI 2.1.k : können Methoden zur Datenreplikation unterscheiden und anwenden (Backup, Synchronisation, Versionierung)"],
  "MI.2.2": [
    "LEHRPLAN MI 2.2.a : können formale Anleitungen erkennen und ihnen folgen (z.B. Koch- und Backrezepte, Spiel- und Bastelanleitungen, Tanzchoreographien)",
    "LEHRPLAN MI 2.2.b : können durch Probieren Lösungswege für einfache Problemstellungen suchen und auf Korrektheit prüfen (z.B. einen Weg suchen, eine Spielstrategie entwickeln). Sie können verschiedene Lösungswege vergleichen",
    "LEHRPLAN MI 2.2.c : können Abläufe mit Schleifen und Verzweigungen aus ihrer Umwelt erkennen, beschreiben und strukturiert darstellen (z.B. mittels Flussdiagrammen)",
    "LEHRPLAN MI 2.2.d : können einfache Abläufe mit Schleifen, bedingten Anweisungen und Parametern lesen und manuell ausführen",
    "LEHRPLAN MI 2.2.e : verstehen, dass ein Computer nur vordefinierte Anweisungen ausführen kann und dass ein Programm eine Abfolge von solchen Anweisungen ist",
    "LEHRPLAN MI 2.2.f : können Programme mit Schleifen, bedingten Anweisungen und Parametern schreiben und testen",
    "LEHRPLAN MI 2.2.g : können selbstentdeckte Lösungswege für einfache Probleme in Form von lauffähigen und korrekten Computerprogrammen mit Schleifen, bedingten Anweisungen und Parametern formulieren",
    "LEHRPLAN MI 2.2.h : können selbstentwickelte Algorithmen in Form von lauffähigen und korrekten Computerprogrammen mit Variablen und Unterprogrammen formulieren",
    "LEHRPLAN MI 2.2.i : können verschiedene Algorithmen zur Lösung desselben Problems vergleichen und beurteilen (z.B. lineare und binäre Suche, Sortierverfahren)"],
  "MI.2.3": [
    "LEHRPLAN MI 2.3.a : können Geräte ein- und ausschalten, Programme starten, bedienen und beenden sowie einfache Funktionen nutzen",
    "LEHRPLAN MI 2.3.b : können sich mit eigenem Login in einem lokalen Netzwerk oder einer Lernumgebung anmelden",
    "LEHRPLAN MI 2.3.c : können Dokumente selbstständig ablegen und wieder finden",
    "LEHRPLAN MI 2.3.d : können mit grundlegenden Elementen der Bedienoberfläche umgehen (Fenster, Menu, mehrere geöffnete Programme)",
    "LEHRPLAN MI 2.3.e : können Betriebssystem und Anwendungssoftware unterscheiden",
    "LEHRPLAN MI 2.3.f : kennen verschiedene Speicherarten (z.B. Festplatten, Flashspeicher, Hauptspeicher) und deren Vor- und Nachteile und verstehen Grösseneinheiten für Daten",
    "LEHRPLAN MI 2.3.g : können bei Problemen mit Geräten und Programmen Lösungsstrategien anwenden (z.B. Hilfe-Funktion, Recherche)",
    "LEHRPLAN MI 2.3.h : können erklären, wie Daten verloren gehen können und kennen die wichtigsten Massnahmen, sich davor zu schützen",
    "LEHRPLAN MI 2.3.i : verstehen die grundsätzliche Funktionsweise von Suchmaschinen",
    "LEHRPLAN MI 2.3.j : können lokale Geräte, lokales Netzwerk und das Internet als Speicherorte für private und öffentliche Daten unterscheiden",
    "LEHRPLAN MI 2.3.k : haben eine Vorstellung von den Leistungseinheiten informationsverarbeitender Systeme und können deren Relevanz für konkrete Anwendungen einschätzen (z.B. Speicherkapazität, Bildauflösung, Rechenkapazität, Datenübertragungsrate)",
    "LEHRPLAN MI 2.3.l : kennen die wesentlichen Eingabe-, Verarbeitungs- und Ausgabeelemente von Informatiksystemen und können diese mit den entsprechenden Funktionen von Lebewesen vergleichen (Sensor, Prozessor, Aktor und Speicher)",
    "LEHRPLAN MI 2.3.m : können das Internet als Infrastruktur von seinen Diensten unterscheiden (z.B. WWW, E-Mail, Internettelefonie, Soziale Netzwerke)",
    "LEHRPLAN MI 2.3.n : können die Risiken unverschlüsselter Datenübermittlung und -speicherung abschätzen"]
}