import React from 'react';
import { Typography, Box, IconButton, Grid, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { questionContainerStyle } from './styledComponents';
import { addOption, removeOption, changeOption, saveEdits } from '../utils/SurveyUtils';
import SurveyQuestion from './SurveyQuestion';
import QuestionControlsView from './CreateSurveys/QuestionControlsView';
import ContextControlsView from './CreateSurveys/ContextControlsView';

// for i18n
import { useMessageService } from '../services/MessageService';
import { buttonStyle } from './styledComponents';

// The view to edit a question
const EditQuestionView = ({ question, setQuestions, setEditingQuestionId }) => {
    // for the translations
    const { getMessage } = useMessageService();

    const handleChange = (prop, value) => {
        setQuestions(prevQuestions =>
            prevQuestions.map(q => q.questionId === question.questionId ? { ...q, [prop]: value } : q)
        );
    };

    return (
        <Box component="div" sx={{ width: '100%', p: 2, margin: "20px" }}>
            <Typography mb="20px" variant="h4" fontWeight="bold" sx={{ color: "rgb(82,82,82)" }}>
                {question.questionType === 'single-text' 
                    ? getMessage("label_edit_context")
                    : getMessage("label_edit_question")
                }
            </Typography>
            <Grid container spacing={2}>
                {question.questionType !== 'single-text' && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={getMessage("label_question_title")}
                            variant="outlined"
                            value={question.question}
                            onChange={e => handleChange('question', e.target.value)}
                        />
                    </Grid>
                )}
                {question.questionType !== 'single-text' && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={getMessage("label_short_name")}
                            variant="outlined"
                            value={question.shortName}
                            onChange={e => handleChange('shortName', e.target.value)}
                        />
                    </Grid>
                )}
                {question.questionType === 'single-text' && (
                    <Grid item xs={12}>
                        <Box>
                            <TextField
                            fullWidth
                            multiline
                            rows={6}
                            variant="outlined"
                            value={
                               question.context
                            }
                            onChange={(e) => {
                                handleChange('context', e.target.value);
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    fontFamily: "monospace"
                                }
                            }}
                        />

                        </Box>
                    </Grid>    
                )}
                {question.questionType !== 'text' && question.questionType !== 'single-text' && (
                    question.options.map((option, index) => (
                        <Grid item xs={12} key={index}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <TextField
                                    fullWidth
                                    label={`${getMessage("label_option")} ${index + 1}`}
                                    variant="outlined"
                                    value={option.label}
                                    onChange={(e) => changeOption(setQuestions, e, question.questionId, index)}
                                />
                                <IconButton onClick={() => removeOption(setQuestions, question.questionId, index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    ))
                )}
                {question.questionType !== 'text' && question.questionType !== 'single-text' && (
                    <Grid item xs={12}>
                        <Button 
                            variant="outlined" 
                            sx={buttonStyle} 
                            onClick={() => addOption(setQuestions, question.questionId)}
                        >
                            {getMessage("label_add_option")}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                        <Button 
                            type="button" 
                            variant="contained" 
                            onClick={() => setEditingQuestionId(null)} 
                            sx={{
                                backgroundColor: "#F7941E", 
                                borderRadius: "50px", 
                                color: "black", 
                                "&:hover": {
                                    backgroundColor: "#D17A1D",
                                }
                            }}
                        >
                            <Typography variant="h5">
                                {getMessage("label_cancel")}
                            </Typography>
                        </Button>
                        
                        <Button 
                            type="button" 
                            variant="contained" 
                            onClick={() => saveEdits(setQuestions, setEditingQuestionId, question.questionId, question)} 
                            sx={{
                                backgroundColor: "#F7941E", 
                                borderRadius: "50px", 
                                color: "black", 
                                "&:hover": {
                                    backgroundColor: "#D17A1D",
                                }
                            }}
                        >
                            <Typography variant="h5">
                                {getMessage("label_validate")}
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const QuestionsListSection = ({
    workshopName,
    questions,
    setQuestions,
    setEditingQuestionId,
    editingQuestionId,
    setFieldValue }) => (

        <div key={workshopName}>
            {workshopName !== "undefined" && 
                (<Typography variant="h4" fontWeight="bold" color="rgb(102,102,102)" m="15px 10px 0px 0px">
                    {workshopName}
                </Typography>)
            }
            
            {questions.map((question, index) => (

                <Box key={index} sx={questionContainerStyle} display="flex" alignItems="center">

                {editingQuestionId === question.questionId ? (

                    <EditQuestionView
                        question={question}
                        setQuestions={setQuestions}
                        setEditingQuestionId={setEditingQuestionId}
                        saveEdits={saveEdits}/>
                ) : (

                    <SurveyQuestion
                        question={question.question}
                        shortName={question.shortName}
                        context={question.context}
                        fieldName={question.fieldName}
                        options={question.options}
                        type={question.questionType}
                        setFieldValue={setFieldValue}
                        titleFontSize="18px"
                        optionFontSize="14px"
                        disabled={true}/>
                )}

                {question.questionType === 'single-text' ? (
                    <ContextControlsView
                        questions={questions}
                        setQuestions={setQuestions}
                        setEditingQuestionId={setEditingQuestionId}
                        question={question}
                    />
                ) : (
                    <QuestionControlsView
                        questions={questions}
                        setQuestions={setQuestions}
                        setEditingQuestionId={setEditingQuestionId}
                        question={question}
                    />
                )}
                </Box>
            ))}
        </div>
);

export default QuestionsListSection;
