import React, { useState } from 'react';
import { Delete, ArrowUpward, ArrowDownward, Edit, ContentCopy } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from '@mui/material';
import { duplicateQuestion, reorderQuestionWithinWorkshop, removeQuestion, changeMandatoryStatus } from '../../utils/SurveyUtils';
import MandatoryToggle from '../MandatoryToogle';

// for i18n
import { useMessageService } from '../../services/MessageService';

// the view with all the elements for the control - edit, delete, up, down, ect...
const QuestionControlsView = ({ questions, setQuestions, setEditingQuestionId, question }) => {

    // for the translations
    const { getMessage } = useMessageService();

    const [isMandatory, setIsMandatory] = useState(question.isMandatory);

    const toggleMandatory = () => {
        // Toggle the state
        setIsMandatory(!isMandatory); 
        // Update the question's mandatory status in the main question array using the utility function
        changeMandatoryStatus(setQuestions, { target: { checked: !isMandatory } }, question.questionId);
    };

    return (
        <Box display="flex" flexDirection="column">
            <MandatoryToggle isMandatory={isMandatory} toggleMandatory={toggleMandatory} />

            <Tooltip title={getMessage("label_tooltip_duplicate_question")}>
                <IconButton onClick={() => duplicateQuestion(questions, setQuestions, question.questionId)} sx={{ color: 'blue' }}>
                    <ContentCopy />
                </IconButton>
            </Tooltip>

            <Tooltip title={getMessage("label_tooltip_edit_question")}>
                <IconButton onClick={() => setEditingQuestionId(question.questionId)} sx={{ color: 'green' }}>
                    <Edit />
                </IconButton>
            </Tooltip>

            <Tooltip title={getMessage("label_tooltip_remove_question")}>
                <IconButton onClick={() => removeQuestion(setQuestions, question.workshop, question.questionId)} sx={{ color: 'red' }}>
                    <Delete />
                </IconButton>
            </Tooltip>
            
            <Tooltip title={getMessage("label_tooltip_move_question_up")}>
                <IconButton onClick={() => reorderQuestionWithinWorkshop(questions, setQuestions, question.workshop, question.questionId, 'up')} >
                    <ArrowUpward />
                </IconButton>
            </Tooltip>
            
            <Tooltip title={getMessage("label_tooltip_move_question_down")}>
                <IconButton onClick={() => reorderQuestionWithinWorkshop(questions, setQuestions, question.workshop, question.questionId, 'down')} >
                    <ArrowDownward />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default QuestionControlsView;