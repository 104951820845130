import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import { Box, TextField, Button, Select, MenuItem, InputLabel, Typography, FormControl, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from "react";
import questionConfig from '../assets/questionsConfig.json';
import { buttonStyle, toolbarConfig } from '../components/styledComponents';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { QuestionType } from '../utils/enums';
import { useMessageService } from '../services/MessageService';
import QuestionTypeSelector from './QuestionTypeSelector';

const AddQuestion = ({ setQuestions, questions, assessmentType, workshops, splitWorkshops }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isUploading, setIsUploading ] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const { getMessage } = useMessageService();

  const getAddQuestionSchema = (splitWorkshops, questionType) => {
    let schema = {
      question: questionType === QuestionType.SINGLE_TEXT ? Yup.string() : Yup.string()
        .required("The question is required")
        .min(5, "The question must be at least 5 characters long"),
      shortName: questionType === QuestionType.SINGLE_TEXT ? Yup.string() : Yup.string()
        .required("The short name is required")
        .min(2, "The short name must be at least 2 characters long")
        .max(40, "The short name must be at most 40 characters long"),
    };

    if (splitWorkshops) {
      schema.workshop = Yup.string().required("The category is required");
    }

    return Yup.object().shape(schema);
  };

  const formConfig = questionConfig[assessmentType] || [];

  const initialQuestion = {
    questionId: "",
    question: "",
    shortName: "",
    context: "",
    questionType: QuestionType.SINGLE_TEXT,
    organizationalType: "",
    isMandatory: false,
    workshop: "",
    options: ["", ""],
    correctAnswer: null,
    explanation: null,
    framework: null,
    competencies: null
  };

  const handleSubmit = (values, { resetForm }) => {
    const newQuestionId = questions.reduce((maxId, question) => 
      Math.max(maxId, parseInt(question.questionId, 10)), 0) + 1;
    
    let editorContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    
    const newQuestion = {
      ...values,
      questionId: newQuestionId.toString(),
      context: editorContent,
      options: values.questionType === QuestionType.TEXT || values.questionType === QuestionType.SINGLE_TEXT
        ? []
        : values.options.map((option, index) => ({ 
            value: `${index + 1}`, 
            label: option 
          })),
    };

    setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
    resetForm();
    setEditorState(EditorState.createEmpty());
    setUploadError(null);
  };

  return (
    <Box p={2} m={2}>
      <Formik
        initialValues={initialQuestion}
        validationSchema={Yup.lazy(values => getAddQuestionSchema(splitWorkshops, values.questionType))}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            {splitWorkshops && (
              <FormControl fullWidth margin="normal">
                <InputLabel id="workshop-label">
                  {getMessage("label_choose_workshop")}
                </InputLabel>
                <Select
                  labelId="workshop-label"
                  id="workshop"
                  name="workshop"
                  value={values.workshop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.workshop && Boolean(errors.workshop)}
                  label={getMessage("label_choose_workshop")}
                >
                  {workshops.map((workshop, index) => (
                    <MenuItem key={index} value={workshop}>{workshop}</MenuItem>
                  ))}
                </Select>
                {touched.workshop && errors.workshop && 
                  <Typography color="error">{errors.workshop}</Typography>
                }
              </FormControl>
            )}

            {values.questionType === QuestionType.SINGLE_TEXT ? (
              <>
                {formConfig
                  .filter(field => field.type !== 'question' && field.type !== 'shortName')
                  .map((field, index) => {
                    switch (field.type) {
                      case 'FormControl':
                        if (field.id === "questionType") {
                          return (
                            <QuestionTypeSelector
                              key={index}
                              name="questionType"
                              label="label_choose_question_type"
                              getMessage={getMessage}
                              sx={{ mb: "15px" }}
                              optionsTypes={field.options}
                            />
                          );
                        }
                        return null;

                      case 'SingleText':
                        return (
                          <Box key={index} margin="normal">
                            <Box 
                              border={1} 
                              borderColor={uploadError ? "error.main" : "grey.400"} 
                              p={2} 
                              borderRadius={1}
                            >
                              <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={setEditorState}
                                toolbar={toolbarConfig}
                              />
                            </Box>
                            {isUploading && (
                              <Typography color="info.main" sx={{ mt: 1 }}>
                                {getMessage("label_uploading_image")}
                              </Typography>
                            )}
                            {uploadError && (
                              <Typography color="error" sx={{ mt: 1 }}>
                                {uploadError}
                              </Typography>
                            )}
                          </Box>
                        );

                      default:
                        return null;
                    }
                  })}
              </>
            ) : (
              <>
                {formConfig.map((field, index) => {
                  switch (field.type) {
                    case 'TextField':
                      return (
                        <TextField
                          key={index}
                          fullWidth
                          margin="normal"
                          id={field.id}
                          name={field.name}
                          label={field.label}
                          value={values[field.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched[field.id] && Boolean(errors[field.id])}
                          helperText={touched[field.id] && errors[field.id]}
                        />
                      );

                    case 'FormControl':
                      return (
                        <QuestionTypeSelector
                          key={index}
                          name={field.id}
                          label={getMessage(field.label)}
                          getMessage={getMessage}
                          sx={{ mb: "15px", mt: "15px" }}
                          optionsTypes={field.options}
                        />
                      );

                    default:
                      return null;
                  }
                })}
              </>
            )}

            {values.questionType !== QuestionType.TEXT && 
             values.questionType !== QuestionType.SINGLE_TEXT && (
              <FieldArray name="options">
                {({ remove, push }) => (
                  <Box>
                    {values.options.map((option, index) => (
                      <Box key={index} display="flex" alignItems="center">
                        <TextField
                          fullWidth
                          margin="normal"
                          name={`options.${index}`}
                          label={`${getMessage("label_option")} ${index + 1}`}
                          value={option}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <IconButton onClick={() => remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                    <Button 
                      onClick={() => push('')} 
                      variant="contained" 
                      sx={buttonStyle}
                    >
                      {getMessage("label_add_option")}
                    </Button>
                  </Box>
                )}
              </FieldArray>
            )}

            <Button 
              type="submit" 
              variant="contained" 
              sx={{ ...buttonStyle, mt: 2 }}
              disabled={isUploading}
            >
              {getMessage("label_submit")}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddQuestion;